import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "../components/Header"
import Layout from "../components/Layout"
import { btn, btnPrimary } from "../ds/button.module.css"
import { WhiteArrowRight } from "../ds/Shapes"

export default function index() {
  return (
    <Layout
      title="AI-Powered Networking for Unmatched Event Engagement"
      description="Empower your attendees to create connections through photos."
    >
      <div className="">
        <div className="" style={{ backgroundColor: "#7251F4" }}>
          <Header isWhiteLogo />
        </div>

        <section className="py-10" style={{ backgroundColor: "#7251F4" }}>
          <div className="px-5 md:px-0 mx-auto md:w-[1080px]">
            <div className="md:flex items-center">
              <div className="md:w-1/2 flex flex-col justify-center text-left">
                {/* <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white"><span style={{ color: '#61F2C2' }}>DOUBLE</span> YOUR <br />ATTENDEES</h1> */}
                <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white">
                  AI-Powered Networking for Unmatched Event Engagement
                </h1>
                <p className="text-white">
                  Empower your attendees to create
                  <br />
                  <span style={{ color: "#61F2C2" }}>
                    connections through photos.
                  </span>
                </p>
                <Link
                  to="/request-a-demo/"
                  className={`${btn} ${btnPrimary} text-xs mt-10 md:mt-32 mb-10 md:mb-0 relative w-max group`}
                >
                  <span className="mr-4 ml-2">Show me how</span>
                  <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
                </Link>
              </div>
              <div className="md:w-1/2 md:ml-0 relative">
                <div className="text-center">
                  <StaticImage src="../images/ripple_hero_1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="md:pt-10">
          <div className="px-5 md:px-0 mx-auto md:w-[1080px]">
            <div className="flex flex-col md:flex-row flex-col-reverse">
              <div className="md:w-1/2 flex flex-col justify-center text-left md:pr-10 md:mt-0">
                <h4
                  className="font-bold text-2xl mb-5"
                  style={{ color: "#7251F4" }}
                >
                  How it works
                </h4>
                <h1
                  className="text-3xl md:text-4xl font-bold md:leading-snug mb-4"
                  style={{ color: "#7251F4" }}
                >
                  Drive purposeful <br />
                  attendee engagement with{" "}
                  <span style={{ color: "#FF6550" }}>smart networking.</span>
                </h1>
                <p>
                Whether it’s sharing expertise, connecting with peers, or gathering leads, Premagic has the right technology to support your next networking event.
                </p>
                <Link
                  to="/request-a-demo/"
                  className={`${btn} ${btnPrimary} text-xs mt-10 mb-10 md:mb-0 relative w-max group`}
                >
                  <span className="mr-4 ml-2">Show me how</span>
                  <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
                </Link>
              </div>
              <div className="md:w-1/2 md:pl-10 mt-10 md:mt-0 -mr-10 md:mr-0">
                <StaticImage
                  src="../images/ripple_how_it_works.png"
                  height={500}
                  alt=""
                />
              </div>
            </div>
            <div className="md:flex mt-20">
              <div className="md:w-1/2 md:pr-10">
                <StaticImage src="../images/ripple_hiw_1.png" alt="" />
              </div>
              <div className="md:w-1/2 flex flex-col justify-center text-left mt-10 md:mt-0 md:pl-10">
                <h1
                  className="text-3xl md:text-4xl font-bold md:leading-snug mb-2"
                  style={{ color: "#7251F4" }}
                >
                  Connect via faces
                </h1>
                <p className="md:pr-32">
                  Help attendees easily connect with others by tapping on any
                  face in the photo gallery to instantly access attendee
                  information.
                </p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row mt-20 md:my-20 flex-col-reverse">
              <div className="md:w-1/2 flex flex-col justify-center text-left md:pr-10 mt-10 md:mt-0">
                <h1
                  className="text-3xl md:text-4xl font-bold md:leading-snug mb-2"
                  style={{ color: "#7251F4" }}
                >
                  Social Media Integration
                </h1>
                <p className="md:pr-32">
                  Attendees can create rich personal profiles by listing
                  relevant information and integrating multiple social profiles
                  to unlock better networking opportunities.{" "}
                </p>
              </div>
              <div className="md:w-1/2 md:pl-10">
                <StaticImage src="../images/ripple_hiw_2.png" alt="" />
              </div>
            </div>
            <div className="md:flex mb-20 mt-20 md:mt-0">
              <div className="md:w-1/2 md:pr-10">
                <StaticImage src="../images/ripple_hiw_3.png" alt="" />
              </div>
              <div className="md:w-1/2 flex flex-col justify-center text-left md:pl-10 mt-10 md:mt-0">
                <h1
                  className="text-3xl md:text-4xl font-bold md:leading-snug mb-2"
                  style={{ color: "#7251F4" }}
                >
                  Engaging Value Proposition
                </h1>
                <p className="md:pr-32">
                  Stand out with a unique value proposition that increases
                  engagement and ROI. Sell more meeting packages to suppliers,
                  sponsorship opportunities to sponsors and leads to exhibitors
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="px-5 md:px-0 mx-auto pt-20 pb-20 md:w-[1080px]">
            <div className="md:flex items-center">
              <div className="md:w-1/3 md:px-2 mb-20 md:mb-0">
                <StaticImage
                  className="md:w-64 rounded-xl"
                  src="../images/ripple_ftrs_1.png"
                  alt=""
                />
                <h1
                  className="text-2xl mt-7 mb-4 md:text-2xl font-bold md:leading-snug mb-2"
                  style={{ color: "#7251F4" }}
                >
                  Increase Engagement
                </h1>
                <p className="md:pr-32">
                  Boost event satisfaction by making great networking
                  opportunities a key part of the experience.
                </p>
              </div>
              <div className="md:w-1/3 md:px-10 mb-20 md:mb-0">
                <StaticImage
                  className="md:w-[273px] rounded-xl"
                  src="../images/ripple_ftrs_2.png"
                  alt=""
                />
                <h1
                  className="text-2xl mt-7 mb-4 md:text-2xl font-bold md:leading-snug mb-2"
                  style={{ color: "#7251F4" }}
                >
                  Beyond Business Cards
                </h1>
                <p className="">
                  No external app required, connect with other attendees
                  effortlessly through their LinkedIn profiles.
                </p>
              </div>
              <div className="md:w-1/3 md:pl-20">
                <StaticImage
                  className="md:w-64 rounded-xl"
                  src="../images/ripple_ftrs_3.png"
                  alt=""
                />
                <h1
                  className="text-2xl mt-7 mb-4 md:text-2xl font-bold md:leading-snug mb-2"
                  style={{ color: "#7251F4" }}
                >
                  Boost ROI
                </h1>
                <p className="">
                  Sell more sponsorship opportunities to sponsors, leads to
                  exhibitors & networking prospects to attendees.
                </p>
              </div>
            </div>
            <div className="text-center">
              <Link
                to="/request-a-demo/"
                className={`${btn} ${btnPrimary} text-xs mt-10 mb-10 md:mb-0 mx-auto relative w-max group`}
              >
                <span className="mr-4 ml-2">Show me how</span>
                <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
              </Link>
            </div>
          </div>
        </section>

        <section
          className="py-20 md:py-0"
          style={{ backgroundColor: "#7251F4" }}
        >
          <div className="px-5 md:px-0 mx-auto md:flex py-10 items-center md:w-[1080px]">
            <div className="md:w-1/2 flex flex-col justify-center relative mb-20 md:mb-0">
              <StaticImage class="" src="../images/posters.png" alt="" />
            </div>
            <div className="md:w-1/2">
              <h4 className="text-white text-center text-2xl">
                <span style={{ color: "#61F2C2" }}>“</span>I’ve never seen a
                networking module as innovative as this! It compliments the
                experience that we design and strive to deliver for all of our
                attendees.<span style={{ color: "#61F2C2" }}>”</span>
              </h4>
              <h5
                className="text-2xl text-center mt-10"
                style={{ color: "#61F2C2" }}
              >
                Malavika Velayanikal
              </h5>
              <p className="text-white text-xl text-center">
                Director of Initiatives & Events SaaSBOOMi
              </p>
            </div>
          </div>
        </section>

        <section className="my-20">
          <div className="px-5 md:px-0 mx-auto flex py-10 items-center flex-wrap md:w-[1080px]">
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
              <StaticImage
                class="w-24"
                src="../images/ripple_ico_1.png"
                alt=""
              />
              <h4
                style={{ color: "#FF6550" }}
                className="text-4xl font-bold my-5"
              >
                5K
              </h4>
              <p>
                connections
                <br />
                powered
              </p>
            </div>
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
              <StaticImage
                class="w-20"
                src="../images/ripple_ico_2.png"
                alt=""
              />
              <h4
                style={{ color: "#FF6550" }}
                className="text-4xl font-bold my-5"
              >
                700K
              </h4>
              <p>
                events
                <br />
                powered
              </p>
            </div>
            <div className="w-1/2 md:w-1/4 text-center">
              <StaticImage
                class="w-20"
                src="../images/ripple_ico_3.png"
                alt=""
              />
              <h4
                style={{ color: "#FF6550" }}
                className="text-4xl font-bold my-5"
              >
                70%
              </h4>
              <p>
                increase in <br />
                attendee engagement
              </p>
            </div>
            <div className="w-1/2 md:w-1/4 text-center">
              <StaticImage
                class="w-24"
                src="../images/ripple_ico_4.png"
                alt=""
              />
              <h4
                style={{ color: "#FF6550" }}
                className="text-4xl font-bold my-5"
              >
                80%
              </h4>
              <p>
                increase in
                <br />
                ROI
              </p>
            </div>
          </div>
        </section>

        {/* <RequestDemo /> */}
      </div>
    </Layout>
  )
}
