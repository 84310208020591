import { StaticImage } from "gatsby-plugin-image"

import icon1 from "../../images/svg/1.svg"
import icon2 from "../../images/svg/2.svg"
import icon3 from "../../images/svg/3.svg"
import icon4 from "../../images/svg/4.svg"

const nasscomData = {
  color: "#FFE9EA",
  heroTitle: `Learn how Nasscom<br /> uses Premagic to<br /> empower sponsor<br /> audience<br /> engagement`,
  heroImage: <StaticImage src="../../images/case-study/nasscom/1.png" alt="" />,
  metrics: {
    values: [
      {
        label: "Attendees",
        value: "1,200",
      },
      {
        label: "Photos",
        value: "3,580",
      },
      {
        label: "Photo Downloads",
        value: "400",
      },
      {
        label: "Social Posts",
        value: "175",
      },
    ],
    title: "Background",
    content: `Nasscom Gcc Conclave is a global gathering that focuses on continuous digital transformation via collaborative innovation while throwing light on the role of India in the hyperconnected world. In its 13th edition, this prominent event brought together tech industry leaders, innovators, and visionaries under one roof.`,
    footer: `The event was organized by the National Association of Software and Service Companies (NASSCOM), a non-governmental trade association and advocacy group that primarily serves the Indian technology industry.`,
    host: "Nasscom",
    organizer: "Nasscom",
    industry: "Technology",
  },
  images: [
    {
      img: <StaticImage src="../../images/case-study/nasscom/2.png" alt="" />,
    },
    {
      img: <StaticImage src="../../images/case-study/nasscom/3.png" alt="" />,
    },
  ],
  gallery: (
    <StaticImage src="../../images/case-study/nasscom/gallery.png" alt="" />
  ),
  caseStudyData: [
    {
      title: "The Challenge",
      subheading: "Rebuilding sponsorship relationships and event engagement",
      info1:
        "After a gap of three years, the NASSCOM GCC Conclave was making a return as an exclusive in-person event. The aim was to highlight the value of face-to-face interaction and networking. But this long sabbatical from the live events scene had brought up numerous concerns that needed to be addressed.",
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/nasscom/avatar.png"
            alt=""
          />
        ),
        message: `“Our immediate priority was rebuilding sponsorship relationships. We needed new and unique ways to showcase the advantages of face-to-face interactions and networking, underlining the tremendous value sponsors could derive from it.“`,
        name: "Parth Premi",
        info: `Sponsorship Manager, <br />Nasscom`,
      },
      info2: `Keeping in mind the pace at which the event landscape has evolved since the pandemic, Parth and his team needed to craft innovative sponsorship models that accommodate both virtual and in-person components, providing sponsors with diverse opportunities for exposure. Ensuring that the attendees enthusiastically participate, engage with sponsors, and generate social media exposure to attract more footfall was also paramount. <br /><br /> Parth was also considering implementing event tech platforms that are aimed to deliver real-time data and valuable insights into attendee engagement, allowing sponsors to gauge the impact of their participation effectively.`,
    },
    {
      title: "The Solution",
      subheading: "Using Premagic to places a strategic spotlight on brands",
      info1: `After looking into various customer platforms, Nasscom ultimately decided that Premagic was the clear choice. not only offered numerous benefits aligned with their requirements but also presented additional features that piqued their interest.<br /><br />
                “Premagic’s AI-based photo distribution feature and in-gallery sponsor brand marketing opportunities were major factors in our decision. We’d tried a few smaller platforms before, but those didn't quite cut it in terms of performance and features.” <br /><br />
                Beyond its functionality and sleek gallery design, Parth and his team were impressed by Premagic's track record of working with some big names in the industry. "Considering their impressive clientele and established reputation in the market, we were confident that we had chosen a reliable company with a solid team and excellent support," noted Parth.`,
    },
    {
      title: "The Results",
      subheading: "",
      info1: `When NASSCOM joined hands with Premagic, they witnessed a remarkable surge in their online presence. Attendees eagerly shared more than a thousand event photos across various social media platforms. This not only expanded their digital footprint but also emphasized its prominence within the tech community. <br /><br />One of their key concerns had been ensuring strategic brand visibility for their sponsors. Premagic was able to put that worry to rest by strategically placing sponsor brands within event galleries.<br /><br />“This simple yet effective strategy offered sponsors an exceptional channel for exposure. What truly impressed us was the versatility – from images to video content and custom calls-to-action, it truly engaged our attendees effectively."`,
      gridViewData: [
        {
          icon: <img src={icon1} alt="" />,
          title: "Amplified Online Presence",
          description: `Attendees shared over 1,000 event photos on social media platforms, expanding NASSCOM's digital reach and underscoring its significance in the tech community.`,
        },
        {
          icon: <img src={icon2} alt="" />,
          title: "Strategic Brand Visibility",
          description: `Premagic addressed this challenge by strategically placing sponsor brands within event galleries, offering a powerful channel for brand exposure. The inclusion of both image and video content, coupled with custom calls-to-action, engaged attendees effectively.`,
        },
        {
          icon: <img src={icon3} alt="" />,
          title: "Enhanced Sponsor Engagement",
          description: `With the ability to add personalized CTAs within the albums. Premagic empowered audiences to actively engage with the sponsor brands, yielding higher sponsor-audience interaction.`,
        },
        {
          icon: <img src={icon4} alt="" />,
          title: "Instant Photo Delivery",
          description: `Utilizing Premagic's seamless photo distribution, attendees received event photos promptly, enhancing their ability to revisit and share memorable moments.`,
        },
      ],
    },
    {
      title: "The Future",
      subheading: `Paving the way for more interactive and impactful events`,
      info1: `The Nasscom team is revising its event strategy for 2023 and beyond. They have many more large events on the calendar, and Parth is excited for Premagic and Nasscom to work together in a partnership for mutual growth and success.`,
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/nasscom/avatar.png"
            alt=""
          />
        ),
        message: `“ We will absolutely consider Premagic as a platform for our future events. We are very happy with our experience. “`,
        name: "Parth Premi",
        info: `Sponsorship Manager, <br />Nasscom`,
      },
    },
  ],
}

export default nasscomData
