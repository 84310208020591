import useWindowDimensions from '../../../utils/useWindowDimensions';

export default function UGCHub(props) {
  const { hashtag, background, data } = props.UGCData;
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 1080;
  const containerWidth = isMobileUp ? '1080px' : '100%';

  return (
    <section className="py-24 relative z-20" style={{ backgroundColor: background }}>
      <div className="px-5 md:px-0 mx-auto" style={{ width: containerWidth }}>
        <p className='text-center text-blue-600 text-lg font-semibold'>{hashtag}</p>
        <h2 className='text-center text-black text-5xl font-bold leading-snug my-3'>Empowering Attendees to <br />Amplify Your Event</h2>
        <p className='text-center'>Transform your event photo dump into engaging user-generated content on social media!</p>
        <div className="columns-1 gap-2 sm:columns-1 md:columns-2 lg:columns-3 w-full mt-10 md:px-20 sm:px-10 lg:px-0">
          {data.map((item, index) => (
            <div className='p-3 w-full break-inside-avoid'>
              <div className='bg-white p-5 rounded-xl'>
                {item?.image}
                <p className={`${item.classNames} text-sm`} dangerouslySetInnerHTML={{ __html: item.content }}></p>
                <div className="flex rounded-2xl mt-5 items-center">
                  <div className="w-12 overflow-hidden rounded-full">{item.avatar}</div>
                  <div className="ml-2 flex flex-col justify-center">
                    <h5 className="font-bold text-sm text-black text-left">{item.name}</h5>
                    <p className="text-xs font-normal text-black text-left">{item.designation}</p>
                  </div>
                  <div className='ml-auto'>{item.logo}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}