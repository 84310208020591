// extracted by mini-css-extract-plugin
export var card = "card-module--card--5ecb2";
export var cardBlue = "card-module--card-blue--2dff1";
export var cardBorderBottomBlack = "card-module--card-border-bottom-black--38e19";
export var cardDarkBlue = "card-module--card-dark-blue--230f5";
export var cardGreen = "card-module--card-green--ce68b";
export var cardIndigo = "card-module--card-indigo--9963b";
export var cardLightBlue = "card-module--card-light-blue--2328f";
export var cardOrange = "card-module--card-orange--55a64";
export var cardPink = "card-module--card-pink--67a71";
export var cardRed = "card-module--card-red--49902";
export var cardSecondary = "card-module--cardSecondary--518ac";