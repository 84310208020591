declare const fbq: any
declare const dataLayer: any

export function trackFBEvents(name: string, data: Record<string, any>) {
  if (typeof fbq !== `undefined`) {
    fbq("track", name, data)
  }
}

export function trackGAEvents(
  name: string,
  data: {
    eventCategory: "acquire"
    eventAction: "button_click" | "buy-button-click"
    eventLabel: string
  }
) {
  if (typeof dataLayer !== `undefined`)
    dataLayer.push({
      event: name,
      ...data,
    })
}
