/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import thumbnailImage from "../images/thumbnails/premagic-web-square.png"

function SEO({ description, lang = "en", meta, metaImage, title, pathName }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            title
            description
            fbId
            url
            keywords
          }
        }
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/reviews/" } }
        ) {
          nodes {
            frontmatter {
              name
            }
            rawMarkdownBody
          }
        }
      }
    `
  )

  const { fbId, url, keywords } = site.siteMetadata
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = description || site.siteMetadata?.title
  const thumbnailImageUrl = metaImage
    ? `${url}${metaImage}`
    : `${url}${thumbnailImage}`

  const pageTitle = `${title} | ${defaultTitle}`
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: keywords.join(","),
        },
        // facebook
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "og:image",
          content: thumbnailImageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "twitter:image",
          content: thumbnailImageUrl,
        },
        {
          name: "fb:app_id",
          content: fbId,
        },
      ].concat(meta)}
    >
      {/* Schema.org tags */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <link rel="canonical" href={`https://premagic.com${pathName}`} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  metaImage: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
