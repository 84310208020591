import React, { useState } from "react"
import {
  btn,
  btnSmall,
  btnPrimary,
  btnDefault,
} from "../../ds/button.module.css"
import { WhiteArrowRight } from "../../ds/Shapes"
import { UploadIcon } from "../../ds/SvgIcons"

type Props = {
  show: boolean
  addData: (key: string, value: any) => void
  submit: () => void
  error: string
  setError: (message: string) => void
  trackEvent: (eventName: string) => void
}
export function GuestList(props: Props) {
  const [file, setFile] = useState({ name: "" })

  const { show, submit, addData, error, setError, trackEvent } = props

  if (!show) return null

  function csvmaker(data) {
    const csvRows = []
    const headers = Object.keys(data)
    csvRows.push(headers.join(","))
    const values = Object.values(data).join(",")
    csvRows.push(values)
    return csvRows.join("\n")
  }

  function download(data) {
    const blob = new Blob([data], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.setAttribute("href", url)
    a.setAttribute("download", "download.csv")
    a.click()
  }

  const onDownload = async function () {
    const data = {
      name: "Carlos A. Decaro",
      email: "carlosadecaro@armyspy.com",
      phone: "9167088459",
      selfie_url:
        "https://images.unsplash.com/photo-1633332755192-727a05c4013d",
    }
    const csvdata = csvmaker(data)
    download(csvdata)
  }

  function handleOnUpload(e) {
    setError("")
    setFile(e.target.files[0])
    addData("attendee_data", e.target.files[0])
  }

  function skipForNow() {
    trackEvent("skip_for_now")
    setFile({ name: "" })
    addData("attendee_data", null)
    submit()
  }

  return (
    <div className="border shadow-lg p-10 rounded-2xl border-slate-100">
      <h4 className="font-semibold text-black">{"Add Your Details ✍️"}</h4>
      <p className="text-sm text-black opacity-70 mt-5 text-sm text-zinc-600">
        Would you like to upload your guest list? We can instantly share your
        Premagic gallery with your entire attendee list.
      </p>
      <div className="mt-10">
        <p className="text-sm text-black opacity-70 mt-5 text-sm text-zinc-600">
          Upload your .CSV file
        </p>
        <div className="md:pr-40">
          <div className="rounded-2xl relative border border-slate-300 p-10 mt-2 flex justify-center flex-col items-center cursor-pointer">
            <UploadIcon />
            <p className="text-sm text-balck opacity-50 text-center mt-5">
              Browse or drag & drop the file
            </p>
            <form>
              <input
                type={"file"}
                onChange={handleOnUpload}
                accept={".csv"}
                className="absolute left-0 right-0 top-0 bottom-0 opacity-0 cursor-pointer"
              />
            </form>
          </div>
        </div>
        <p className="text-sm text-green-600 mt-5 text-sm text-zinc-600">
          {file?.name}
        </p>
        <p className="text-xs mt-5 text-sm text-zinc-600">
          CSV should have headers{" "}
          <span className="text-green-600">name, email, phone, selfie_url</span>
          <a className="ml-1" href="#" onClick={() => onDownload()}>
            see sample file
          </a>
        </p>
        {error && <p className="text-red-600 text-sm pt-5">{error}</p>}
      </div>
      <div className="flex">
        <button
          id="submit_with_attendee_data"
          className={`${btn} ${btnPrimary} ${btnSmall} text-xs relative group mt-10`}
          onClick={() => {
            submit()
            trackEvent("finish")
          }}
          disabled={file?.name.length === 0}
        >
          <span className="mr-4 ml-2">Finish</span>
          <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
        </button>
        <button
          id="submit_without_attendee_data"
          className={`${btn} ${btnDefault} ${btnSmall} text-xs relative group mt-10 ml-5`}
          onClick={() => {
            skipForNow()
          }}
        >
          <span className="mr-4 ml-2">Skip for now</span>
          <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
        </button>
      </div>
    </div>
  )
}
