import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { btn, btnDefault, btnPrimary } from "../../ds/button.module.css"
import { ArrowRightWithGradient, WhiteArrowRight } from "../../ds/Shapes"
import useWindowDimensions from "../../utils/useWindowDimensions"
import HeroVideoMobile from "../../images/events_hero_mobile.mp4"
import { GreenCapsule } from "../../ds/SvgIcons"
import { OrangeCapsule } from "../../ds/SvgIcons"
import LeftFlow from "../../images/svgs/leftflow.svg"
import RightFlow from "../../images/svgs/rightflow.svg"
import TopFlow from "../../images/svgs/topflow.svg"
import BottomFlow from "../../images/svgs/bottomflow.svg"
import PremagicWhiteLogo from "../../images/logo/premagic-logo-white.svg"

const ecosystemData = {
  challenges: [
    "Expensive Event Marketing",
    "Delayed Photo Distribution",
    "Low Sponsor Engagement",
    "Lack of Brand Visibility",
    "Zero UGC Growth",
  ],
  solutions: [
    "Data & Analytics",
    "AI-Based Photo Distribution",
    "Customized Registration Page",
    "Face Recognition",
  ],
  results: [
    "Free Event Marketing",
    "Realtime Photo Distribution",
    "More ROI for Sponsors",
    "Positive Brand Association",
    "Boost Social Engagement",
  ],
}

export function PreToPostEvent() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"
  if (isMobileUp) {
    return (
      <div style={{ width: containerWidth }} className="mx-auto mt-20 mb-40">
        <h2 className="text-black text-5xl font-bold text-center leading-tight md:leading-snug">
          From Pre-Event to Post-Event, <br />
          we’ve got you covered.
        </h2>
        <p className="mt-10 mb-14 text-base md:text-lg opacity-60 text-center px-5 md:px-0">
          What solutions does Premagic offer throughout the various phases of an
          event?
        </p>
        <div className="flex">
          <div className="w-4/12 px-6 relative">
            <div
              className="absolute left-1/2 h-0.5 right-0 top-14"
              style={{ backgroundColor: "#ddd6fe" }}
            ></div>
            <div className="absolute top-12 left-1/2 -translate-x-1/2 bg-white px-10">
              <div
                className="w-5 h-5 rounded-full"
                style={{ backgroundColor: "#7250F4" }}
              ></div>
            </div>
            <p className="text-black text-xl font-semibold text-center mb-8">
              Pre Event
            </p>
            <p className="mt-20 text-black font-medium text-base mb-4 bg-rose-50 rounded-xl p-5 text-center">
              Event Advocacy Posters
            </p>
            <p className="text-black font-medium text-base mb-4 bg-rose-50 rounded-xl p-5 text-center">
              Registration
            </p>
            <p className="text-black font-medium text-base mb-4 bg-rose-50 rounded-xl p-5 text-center">
              Landing page
            </p>
            <p className="text-black font-medium text-base mb-4 bg-rose-50 rounded-xl p-5 text-center">
              Face check-in
            </p>
            <p className="text-black font-medium text-base mb-4 bg-rose-50 rounded-xl p-5 text-center">
              Badge creation
            </p>
          </div>
          <div className="w-4/12 px-6 relative">
            <div
              className="absolute left-0 h-0.5 right-0 top-14"
              style={{ backgroundColor: "#ddd6fe" }}
            ></div>
            <div className="absolute top-12 left-1/2 -translate-x-1/2 bg-white px-10">
              <div
                className="w-5 h-5 rounded-full"
                style={{ backgroundColor: "#7250F4" }}
              ></div>
            </div>
            <p className="text-black text-xl font-semibold text-center mb-8">
              During Event
            </p>
            <p className="mt-20 text-black font-medium text-base mb-4 bg-rose-50 rounded-xl p-5 text-center">
              AI-based photo delivery  
            </p>
            <p className="text-black font-medium text-base mb-4 bg-rose-50 rounded-xl p-5 text-center">
              UGC prompt
            </p>
            <p className="text-black font-medium text-base mb-4 bg-rose-50 rounded-xl p-5 text-center">
              Sponsored Content
            </p>
          </div>
          <div className="w-4/12 px-6 relative">
            <div
              className="absolute left-0 h-0.5 right-1/2 top-14"
              style={{ backgroundColor: "#ddd6fe" }}
            ></div>
            <div className="absolute top-12 left-1/2 -translate-x-1/2 bg-white px-10">
              <div
                className="w-5 h-5 rounded-full"
                style={{ backgroundColor: "#7250F4" }}
              ></div>
            </div>
            <p className="text-black text-xl font-semibold text-center mb-8">
              Post Event
            </p>
            <p className="mt-20 text-black text-base mb-4 bg-rose-50 font-medium text-center rounded-xl p-5">
              Ripple - Networking Module
            </p>
            <p className="text-black text-base mb-4 bg-rose-50 font-medium text-center rounded-xl p-5">
              Analytics
            </p>
            <p className="text-black text-base mb-4 bg-rose-50 font-medium text-center rounded-xl p-5">
              Sparks - Event Memories
            </p>
            <p className="text-black text-base mb-4 bg-rose-50 font-medium text-center rounded-xl p-5">
              Digital Asset Management
            </p>
          </div>
        </div>
      </div>
    )
  }
  return <div></div>
}

export default PreToPostEvent
