import React from "react"
import Layout from "../../../components/Layout"
import Header from "../../../components/Header"
import CaseStudy, {
  CASE_STUDY_TYPES,
} from "../../../components/case-study/CaseStudy"
import ThumbnailImage from "../../../images/thumbnails/nasscom_thumb.png"

export function Index() {
  return (
    <Layout
      title="Learn how Nasscom uses Premagic to empower sponsor audience engagement"
      description="Learn how Nasscom uses Premagic to empower sponsor audience engagement"
      metaImage={ThumbnailImage}
    >
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.NASSCOM} />
    </Layout>
  )
}

export default Index
