import React from "react"

import Layout from "../components/Layout"
import Header from "../components/Header"
import { StaticImage } from "gatsby-plugin-image"

const backers = [
  {
    name: "300 ventures",
    hideName: true,
    link: "https://www.linkedin.com/company/300-ventures/",
    logo: (
      <StaticImage
        width={120}
        src="../images/our-backers/300ventures-logo.webp"
        alt="image"
      />
    ),
  },
  {
    name: "Sujayath Ali",
    link: "https://www.linkedin.com/in/sujayath/",
    logo: (
      <StaticImage
        width={120}
        src="../images/our-backers/sujayath.jpeg"
        alt="image"
        className="rounded-full"
      />
    ),
  },
  {
    name: "Sirish Kosaraju",
    link: "https://www.linkedin.com/in/sirishkosaraju/",
    logo: (
      <StaticImage
        width={120}
        src="../images/our-backers/sireesh.jpg"
        alt="image"
        className="rounded-full"
      />
    ),
  },
  {
    name: "Rajesh Padinjaremadam",
    link: "https://www.linkedin.com/in/rajeshtp/",
    logo: (
      <StaticImage
        width={120}
        src="../images/our-backers/rajesh.jpg"
        alt="image"
        className="rounded-full"
      />
    ),
  },
  {
    name: "Mohammed Hisamuddin",
    link: "https://www.linkedin.com/in/hisamuddin/",
    logo: (
      <StaticImage
        width={120}
        src="../images/our-backers/hisam.jpeg"
        alt="image"
        className="rounded-full"
      />
    ),
  },
  {
    name: "Sharique Samsudheen",
    link: "https://www.linkedin.com/in/shariquesamsudheen/",
    logo: (
      <StaticImage
        width={120}
        src="../images/our-backers/sharique.jpeg"
        alt="image"
        className="rounded-full"
      />
    ),
  },
  {
    name: "Kerala Startup mission",
    hideName: true,
    link: "https://startupmission.kerala.gov.in/",
    logo: (
      <StaticImage
        width={200}
        src="../images/our-backers/startup.png"
        alt="image"
        className="rounded-full"
      />
    ),
  },
]

export default function AboutUs() {
  return (
    <Layout
      title="About Premagic"
      description="We are building Premagic with a vision to support photographers and enhance their businesses. Through our
          product offerings, we aim to create a platform for photographers that equip them with an indispensable set of
          tools."
    >
      <Header />
      <section id="about" className="pm-container py-12">
        <h2 className="font-light text-4xl py-10">
          About <span className="font-bold">Us</span>
        </h2>
        <div className="flex justify-center">
          <p className="w-full md:w-2/3 text-center italic">
            We are an AI-powered event tech platform designed to enhance organic
            event marketing. Our mission? To turn every attendee into a content
            creator. We offer comprehensive support from pre-event to
            post-event, enhancing the organic reach of companies and brands that
            use events in their marketing.
          </p>
        </div>
        <h3 className="font-light text-4xl py-10 mt-16">
          Our <span className="font-bold">Backers</span>
        </h3>
        <div className="grid grid-rows-4 grid-cols-2 gap-12 md:grid-cols-3">
          {backers.map(backer => (
            <a
              key={backer.name}
              href={backer.link}
              target="_blank"
              rel="noreferrer"
              className="flex flex-col justify-center	items-center"
            >
              {backer.logo}
              {!backer.hideName && (
                <div className="font-bold text-gray-900 mt-4 text-center">
                  {backer.name}
                </div>
              )}
            </a>
          ))}
        </div>
      </section>
    </Layout>
  )
}
