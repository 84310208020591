import { StaticImage } from "gatsby-plugin-image"

import icon1 from "../../images/svg/1.svg"
import icon2 from "../../images/svg/2.svg"
import icon3 from "../../images/svg/3.svg"
import icon4 from "../../images/svg/4.svg"

const umagineData = {
  color: "#FFF0F0",
  heroTitle: `Learn how<br /> Premagic helped in<br /> UmagineChennai's<br /> grand success`,
  heroImage: <StaticImage src="../../images/case-study/umagine/1.png" alt="" />,
  metrics: {
    values: [
      {
        label: "Attendees",
        value: "18,367",
      },
      {
        label: "Photos",
        value: "17,840",
      },
      {
        label: "Photo Downloads",
        value: "5,230",
      },
      {
        label: "Social Posts",
        value: "265",
      },
    ],
    title: "Background",
    content: `UmagineChennai is Asia's largest three-day summit on technology and innovation with more than 250 industry veterans and over 18,000 delegates in attendance. The summit features discussions on technology and entrepreneurship growth, including keynotes, panel discussions, investment pitches, product showcases.`,
    footer: `Hosted by the state of Tamil Nadu through ELCOT, this event was organized by CII (Confederation of Indian Industry).`,
    host: "ELCOT (Electronics Corporation of Tamil Nadu Limited)",
    organizer: "CII (Confederation of Indian Industry)",
    industry: "Information & Technology",
  },
  images: [
    {
      img: <StaticImage src="../../images/case-study/umagine/2.png" alt="" />,
    },
    {
      img: <StaticImage src="../../images/case-study/umagine/3.png" alt="" />,
    },
  ],
  gallery: (
    <StaticImage src="../../images/case-study/umagine/gallery.png" alt="" />
  ),
  caseStudyData: [
    {
      title: "The Challenge",
      subheading:
        "Planning a maiden event while delivering an experience that goes beyond just networking.",
      info1:
        "With such a momentous event being held for the first time in Tamil Nadu and on such a grand scale, expectations were sky-high. Umagine was touted to be one of the key initiatives in helping TN reach its trillion-dollar economy ambition. To this end, the Umagine team had three primary goals for the event: generating brand awareness, engaging attendees and driving revenue.",
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/umagine/avatar.png"
            alt=""
          />
        ),
        message: `“ Having a strong event management tool to support a robust attendee experience is integral to the success of any event, especially when it's a first-time event. “`,
        name: "Kazhal Vendhan",
        info: "Manager, CII",
      },
      info2: `As it was a first-time venture, much of the audience that Umagine hoped to attract didn't know what to expect from this new, large-scale, world-class conference. To ensure the best possible event experience, the organizers were on the look-out for a powerful event management tool for efficient multimedia planning, content sharing, and enhanced visibility.`,
    },
    {
      title: "The Solution",
      subheading: "Leveraging AI to deliver a stronger event experience",
      info1: `Having encountered Premagic at a recent event, Kazhal recognized the potential that Premagic had. <br /><br />
                "This event would be filled with innovators and tech whizzes. We needed tools to ensure that the event matched the vibe and tech-savviness of the audience. And what better way to do it than with AI?" <br /><br />
                Choosing a platform that employed AI-powered face recognition was a deliberate choice for the team who were committed to delivering a strong event experience with no hiccups or delays. And Kazhal soon found that Premagic had more to offer than just photo delivery. He explained, "Finding effective ways to showcase our partners and sponsors is always a challenge. I found Premagic’s solution to bring value to these brands truly ingenious." He was also particularly impressed with the gallery's clean and seamless user interface.`,
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/umagine/avatar.png"
            alt=""
          />
        ),
        message: `“ I liked that there was no extra hassle for the audience having to download an app or log in anywhere to view their photos. Everything was just neatly and readily available on their WhatsApp. It's just brilliant! “`,
        name: "Kazhal Vendhan",
        info: "Manager, CII",
      },
    },
    {
      title: "The Results",
      subheading:
        "Uniting Creativity and Innovation to deliver a powerful event experience",
      info1: `Overall, the event was a resounding success with over 18,000 attendees, all treated to a beautifully organized experience. According to Kazhal, "Premagic's AI solutions left a big and lasting impression that truly took our event to the next level. Our guests loved the automated photo delivery!" <br /><br /> Here's a quick recap of what went down during those 3 action-packed three days:`,
      gridViewData: [
        {
          icon: <img src={icon1} alt="" />,
          title: "Seamless Photo Delivery",
          description: `Thanks to Premagic's AI-powered photo distribution, attendees received their event photos instantly via WhatsApp or email, and could continue accessing the photos even after the event had concluded through the Premagic online gallery incorporated directly on the Umagine website.`,
        },
        {
          icon: <img src={icon2} alt="" />,
          title: "Massive Photo Reach",
          description: `Over the course of the event, more than 17,000 photos were distributed to the attendees, with more than 200 social media posts and 900+ shares!`,
        },
        {
          icon: <img src={icon3} alt="" />,
          title: "Impactful Social Presence",
          description: `The event's digital footprint skyrocketed with more than 40,000 impressions generated through social media posts.`,
        },
        {
          icon: <img src={icon4} alt="" />,
          title: "Strategic Brand Visibility",
          description: `Showcasing sponsor brands along with custom calls-to-action, engaged attendees and opened pathways to actively engage with sponsor brands, resulting in higher sponsor-audience interaction.`,
        },
      ],
    },
    {
      title: "The Future",
      subheading: `Establishing the benchmark for Umagine's future`,
      info1: `After experiencing the magic of Premagic, Kazhal and his team are not just confident but eager to jointly create more successful events in future.`,
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/umagine/avatar.png"
            alt=""
          />
        ),
        message: `" This year was pivotal for us. We were introducing a brand new event scene, building it from the ground up. With Premagic’s help, we believe we've set a new standard for tech and innovation events in the country. “`,
        name: "Kazhal Vendhan",
        info: "Manager, CII",
      },
      info2: `With Premagic continually evolving and adding new features, Kazhal is confident that together they will elevate Umagine to new heights in the coming year.`,
    },
  ],
}

export default umagineData
