import React from "react"
import Layout from "../../../components/Layout"
import Header from "../../../components/Header"
import CaseStudy, {
  CASE_STUDY_TYPES,
} from "../../../components/case-study/CaseStudy"
import ThumbnailImage from "../../../images/thumbnails/aios.png"

export function Index() {
  return (
    <Layout
      title="Discover how Premagic Resolved DAM Challenges at AIOC Kochi"
      description="Discover how Premagic Resolved DAM Challenges at AIOC Kochi"
      metaImage={ThumbnailImage}
    >
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.AIO} />
    </Layout>
  )
}

export default Index
