import React, { useState } from "react"
import useWindowDimensions from "../../utils/useWindowDimensions"
import { ParseLink } from "./ParseLink"
import { Details } from "./Details"
import { GuestList } from "./GuestList"
import { Success } from "./Success"
import { externalUpload } from "../../services/externalUploaderService"
import { trackGAEvents } from "../../services/EventTracker"

export function Hero() {
  const { width } = useWindowDimensions()
  const [showParseLinkComponent, toggleParseLinkComponent] = useState(true)
  const [showDetailsComponent, toggleDetailsComponent] = useState(false)
  const [showGuestListComponent, toggleGuestListComponent] = useState(false)
  const [showSuccessComponent, toggleSuccessComponent] = useState(false)

  const [error, setError] = useState("")

  const [data, setData] = useState({})

  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"

  function addData(key, value) {
    const existingData = data
    existingData[key] = value
    setData(existingData)
  }

  function onSubmit() {
    console.log(data)
    externalUpload(data)
      .then(response => {
        toggleGuestListComponent(false)
        toggleSuccessComponent(true)
      })
      .catch(error => {
        setError(error?.error?.message || "")
      })
  }

  function trackEvent(eventName) {
    trackGAEvents(eventName, {
      eventCategory: "acquire",
      eventAction: "button_click",
      eventLabel: eventName,
    })
  }

  return (
    <div style={{ width: containerWidth }} className="mx-auto mt-10 mb-40">
      <div className="md:flex items-center px-5 md:px-0">
        <div className="md:w-1/2">
          <h1 className="font-bold text-3xl md:text-4xl text-black leading-tight">
            Create your <br />
            personalised AI <br />
            event gallery in less <br />
            than <span style={{ color: "#7251F4" }}>3 minutes!</span>
          </h1>
          <p className="opacity-70 mt-5">
            No signups, no fuss, no hassle - easily create and share stunning
            online galleries instantly!
          </p>
        </div>
        <div className="md:w-1/2 mt-20 md:mt-0">
          <ParseLink
            show={showParseLinkComponent}
            toggleDetailsComponent={toggleDetailsComponent}
            toggleParseLinkComponent={toggleParseLinkComponent}
            trackEvent={trackEvent}
            addData={addData}
          />

          <Details
            show={showDetailsComponent}
            toggleDetailsComponent={toggleDetailsComponent}
            toggleGuestListComponent={toggleGuestListComponent}
            trackEvent={trackEvent}
            addData={addData}
          />

          <GuestList
            show={showGuestListComponent}
            error={error}
            setError={setError}
            addData={addData}
            submit={onSubmit}
            trackEvent={trackEvent}
          />

          <Success show={showSuccessComponent} />
        </div>
      </div>
    </div>
  )
}

export default Hero
