import React from "react"
import LogoStrip from "./LogoStrip"

export function Clients() {
  return (
    <section className="my-10">
      <div className="pm-container">
        <LogoStrip fullWidth />
      </div>
    </section>
  )
}

export default Clients
