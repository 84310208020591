import React, { useEffect, useRef, useState } from "react"

import Layout from "../components/Layout"
import Header from "../components/Header"
import { getDownloadLink } from "../services/desktopAppService"
const fallBackUrl =
  "https://premagic.freshdesk.com/support/solutions/articles/44001711898-download-premagic-app-"
export default function DownloadPage() {
  const $downloadLink = useRef(null)
  const [downloadLink, setDownloadLink] = useState("")

  useEffect(() => {
    const link = getDownloadLink()
    if (!link) return
    setDownloadLink(link)

    setTimeout(() => {
      $downloadLink?.current?.click()
    }, 100)
  }, [])

  return (
    <Layout title="Downloads">
      <div>
        <div>
          <Header />

          <div className="pm-container">
            <h1 className="py-20 font-bold">Download Premagic</h1>
          </div>
        </div>
        <div className="pm-container">
          <div className="pb-64">
            {/*        Mobile view */}
            <div className="container mx-auto text-center lg:hidden xl:hidden">
              <p className="text-blue mt-5">
                You can only use Premagic using a computer or a laptop at this
                time.
              </p>
            </div>
            {/*        Desktop */}
            <div className="container mx-auto hidden sm:hidden md:hidden lg:block xl:block">
              <p className="text-blue mt-5">
                Your download of Premagic app will start automatically....
                Please
                <a
                  className="underline bg-pink-200 px-3 py-1 ml-1 rounded-full text-gray-800 no-underline"
                  href={downloadLink}
                  ref={$downloadLink}
                  target="_blank"
                  download
                  title="Download Premagic"
                >
                  click here to download manually.
                </a>
              </p>
              <p className="text-gray-600 mt-5">
                You can also download from mirror link from
                <a
                  className="underline ml-1 "
                  target="_blank"
                  rel="noopener noreferrer"
                  href={fallBackUrl}
                >
                  here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
