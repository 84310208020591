import React from "react"

import Layout from "../components/Layout"
import Header from "../components/Header"

export default function RefundPolicy() {
  const isBrowser = () => typeof window !== "undefined"

  const isUAEDomain = isBrowser() && window.location.host.includes("ae")
  return (
    <Layout title="Refund Policy" description="30 days refund policy">
      <Header />
      <section id="about" className="pm-container py-12">
        <h2 className="font-light text-4xl pt-10">
          Refund <span className="font-bold">Policy</span>
        </h2>
        <h3 className="text-2xl pt-10 pb-3">
          30 Day Money Back Guarantee & Refund Policy
        </h3>
        <p className="w-full md:w-2/3 mb-10">
          At Premagic.com, We want to ensure that you are 100% happy with your
          purchase.
          <ol>
            <li>
              If you have technical or sales queries, do not hesitate to contact
              us.
            </li>
            <li>
              If after you attempt to resolve issues with support staff and feel
              the product(s) you purchased does/do not best fit your
              requirements, we want to make things right.
            </li>
          </ol>
        </p>
        <p className="w-full md:w-2/3 mb-10">
          Our policy offers a full refund within 30 days of your date of
          purchase. We’d love to know what went wrong and how we can improve, so
          please include details about the reason for your refund request if you
          reach out to us directly.
        </p>

        <p className="w-full md:w-2/3 mb-10">
          Premagic.com and our payment process submit the refund immediately and
          make every attempt to process the refund as quickly as possible. Your
          financial institution can take up to 20 days for the refund to reflect
          in your bank account/card. To request a refund please send an email to{" "}
          {isUAEDomain ? (
            <a href="mailto:support@premagic.ae">support@premagic.ae</a>
          ) : (
            <a href="mailto:support@premagic.com">support@premagic.com</a>
          )}
        </p>
      </section>
    </Layout>
  )
}
