import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import { btn, btnPrimary } from "../../../ds/button.module.css"

import { WhiteArrowRight } from "../../../ds/Shapes"

import useWindowDimensions from "../../../utils/useWindowDimensions"

const caseStudyList = [
  {
    logo: <StaticImage className='w-40' src="../../../images/client-logos/33.png" alt="" />,
    heroImage: <StaticImage src="../../../images/case-study/stepconference/1.png" alt="" />,
    url: 'step-conference',
    title: `Discover how Step 2024 turned attendees into advocates with Premagic`,
    color: '#d9e2ff',
    testimonial: {
      img: <StaticImage src="../../../images/case-study/stepconference/avatar.png" alt="" />,
      message: `“Since our attendees are at the forefront of technology, we felt that relying on outdated solutions would be a disservice. We wanted to elevate the event and the overall attendee journey by investing in a versatile, AI-driven application like Premagic.“`,
      name: 'Dana Sakr',
      info: 'Marketing Manager, Step Conference.'
    },
  },
  {
    logo: (
      <StaticImage
        className="w-40"
        src="../../../images/client-logos/17.png"
        alt=""
      />
    ),
    heroImage: (
      <StaticImage src="../../../images/case-study/umagine/1.png" alt="" />
    ),
    url: "umagine",
    title: `UmagineChennai brought a new level of excitement to the event, using Premagic’s solutions`,
    color: "#FFF0F0",
    values: [
      {
        label: "Attendees",
        value: "18,367",
      },
      {
        label: "Photos",
        value: "17,840",
      },
      {
        label: "Photo Downloads",
        value: "5,230",
      },
    ],
    testimonial: {
      img: (
        <StaticImage
          src="../../../images/case-study/umagine/avatar.png"
          alt=""
        />
      ),
      message: `“Premagic's partnership with UmagineChennai brought a new level of excitement to the event, making it an unforgettable celebration of creativity and innovation.“`,
      name: "Kazhal Vendhan",
      info: "Manager, CII",
    },
  },
  {
    logo: (
      <StaticImage
        className="w-28"
        src="../../../images/client-logos/15.png"
        alt=""
      />
    ),
    heroImage: (
      <StaticImage src="../../../images/case-study/saasboomi/1.png" alt="" />
    ),
    url: "saasboomi",
    title: `SaaSBOOMi experienced a transformative shift in their event experience, and in boosting their engagement`,
    color: "#FFF5E4",
    values: [
      {
        label: "Attendees",
        value: "1,230",
      },
      {
        label: "Photos",
        value: "5,450",
      },
      {
        label: "Photo Downloads",
        value: "1,250",
      },
    ],
    testimonial: {
      img: (
        <StaticImage
          src="../../../images/case-study/saasboomi/avatar.png"
          alt=""
        />
      ),
      message: `“Managing event photos was once a headache, with guest requests for immediate access. Thanks to Premagic, it's now hassle-free! We're excited to use it for all our future events!“`,
      name: "Malavika Velayanikal",
      info: `Director of Initiatives & <br /> Events, SaaSBOOMi`,
    },
  },
  {
    logo: (
      <StaticImage
        className="w-36"
        src="../../../images/client-logos/20.png"
        alt=""
      />
    ),
    heroImage: (
      <StaticImage src="../../../images/case-study/nasscom/1.png" alt="" />
    ),
    url: "nasscom",
    title: `NASSCOM paved the way for a more interactive and impactful event experience`,
    color: "#FFE9EA",
    values: [
      {
        label: "Attendees",
        value: "1,200",
      },
      {
        label: "Photos",
        value: "3,580",
      },
      {
        label: "Photo Downloads",
        value: "400",
      },
    ],
    testimonial: {
      img: (
        <StaticImage
          src="../../../images/case-study/nasscom/avatar.png"
          alt=""
        />
      ),
      message: `“Premagic delivered instant photos to our delegates, which was an unprecedented experience for us. It added a remarkable touch to our event, making it truly memorable. We're thrilled with the results!“`,
      name: "Parth Premi",
      info: `Sponsorship Manager,<br />NASSCOM`,
    },
  },
]

export function CasestudyLists() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"

  return (
    <section>
      <div className="mx-auto" style={{ width: containerWidth }}>
        {caseStudyList.map(item => (
          <div
            className="p-8 md:p-20 my-10 md:my-20 rounded-2xl"
            style={{ backgroundColor: item.color }}
          >
            <div className="md:flex items-center">
              <div className="md:w-1/2 md:pr-10">
                <div className="p-5 bg-white w-max rounded-xl">{item.logo}</div>
                <h5 className={`font-semibold text-black text-2xl font-medium mt-7 ${!item?.values && 'mb-8'}`}>
                  {item.title}
                </h5>
                {item?.values?.length > 0 && <div className="flex text-left justify-between my-12">
                  {item?.values?.map(value => (
                    <div className="">
                      <h3 className="text-black text-2xl">{value.value}</h3>
                      <p className="text-black text-sm font-normal opacity-60">
                        {value.label}
                      </p>
                    </div>
                  ))}
                </div>}
                <p className="text-sm">{item.testimonial.message}</p>
                <div className="flex mt-5">
                  <div className="w-14 overflow-hidden rounded-full">
                    {item.testimonial.img}
                  </div>
                  <div className="ml-5 flex flex-col justify-center">
                    <h5 className="font-bold text-sm text-black text-left">
                      {item.testimonial.name}
                    </h5>
                    <p
                      className="text-xs font-normal text-black text-left"
                      dangerouslySetInnerHTML={{
                        __html: item.testimonial.info,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2">
                <div className="mt-10 md:mt-0">
                  <div className="-ml-12 md:ml-0">{item.heroImage}</div>
                  <Link
                    to={item.url}
                    className={`${btn} ${btnPrimary} text-xs mt-10 translate-x-1 mb-10 md:mb-0 relative w-max group ml-14`}
                  >
                    <span className="mr-4 ml-2">Read Full Story</span>
                    <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default CasestudyLists
