import React from "react"
import Layout from "../../../components/Layout"
import Header from "../../../components/Header"
import CaseStudy, {
  CASE_STUDY_TYPES,
} from "../../../components/case-study/CaseStudy"
import ThumbnailImage from "../../../images/thumbnails/gtech.png"
import MagicWithUs from "../../../components/inner-page/MagicWithUs"

export function Index() {
  return (
    <Layout
      title="Discover How Premagic Powered GTECH Kerala Marathon 2024 with AI-Driven Photo Distribution"
      description="Discover How Premagic Powered GTECH Kerala Marathon 2024 with AI-Driven Photo Distribution"
      metaImage={ThumbnailImage}
    >
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.GTECH} />
    </Layout>
  )
}

export default Index
