import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  btn,
  btnDefault,
  playButton,
  playButtonIcon,
} from "../../ds/button.module.css"
import { ArrowRightWithGradient } from "../../ds/Shapes"
import { cardBorderBottomBlue } from "../../ds/card.module.css"
import useWindowDimensions from "../../utils/useWindowDimensions"
import { Calendar } from "../../ds/SvgIcons"

const eventTypes = [
  {
    logo: (
      <StaticImage width={150} src="../../images/client-logos/1.png" alt="" />
    ),
  },
  {
    logo: (
      <StaticImage width={150} src="../../images/client-logos/2.png" alt="" />
    ),
  },
  {
    logo: (
      <StaticImage width={100} src="../../images/client-logos/3.png" alt="" />
    ),
  },
  {
    logo: (
      <StaticImage width={100} src="../../images/client-logos/4.png" alt="" />
    ),
  },
  {
    logo: (
      <StaticImage width={100} src="../../images/client-logos/5.png" alt="" />
    ),
  },
  {
    logo: (
      <StaticImage width={100} src="../../images/client-logos/7.png" alt="" />
    ),
  },
  {
    logo: (
      <StaticImage width={100} src="../../images/client-logos/8.png" alt="" />
    ),
  },
  {
    logo: (
      <StaticImage width={100} src="../../images/client-logos/9.png" alt="" />
    ),
  },
  {
    logo: (
      <StaticImage width={100} src="../../images/client-logos/10.png" alt="" />
    ),
  },
  {
    logo: (
      <StaticImage width={100} src="../../images/client-logos/11.png" alt="" />
    ),
  },
  {
    logo: (
      <StaticImage width={100} src="../../images/client-logos/12.png" alt="" />
    ),
  },
]

export function TrustedBy() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 768
  if (isMobileUp) {
    return (
      <div className="pm-container mx-auto px-6 my-10">
        <h1 className="text-center font-bold text-5xl mt-10 leading-tight text-black leading-snug">
          The world's highest-performing <br /> marketing teams trust Premagic
          with <br />
          their events
        </h1>
        <div className="flex flex-wrap w-3/4 mx-auto justify-center center-center align-center mt-10">
          {eventTypes.map(item => (
            <div className="rounded-xl p-8">{item.logo}</div>
          ))}
        </div>
      </div>
    )
  }
  return (
    <div className="pm-container mx-auto px-6 my-10">
      <h1 className="text-center font-bold text-3xl mt-10 leading-tight text-black leading-snug">
        One corporate
        <br /> event management software, endless possibilities
      </h1>
      <p className="text-black text-center mt-10 mb-20">
        Whether you want to raise brand awareness, drive revenue or engage
        attendees, Premagic being the perfect choice.
      </p>
      <div className="flex flex-wrap">
        {eventTypes.map(item => (
          <div className="rounded-xl p-8">{item.logo}</div>
        ))}
      </div>
    </div>
  )
}

export default TrustedBy
