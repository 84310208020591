import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  btn,
  btnDefault,
  playButton,
  playButtonIcon,
} from "../../ds/button.module.css"
import { ArrowRightWithGradient } from "../../ds/Shapes"
import useWindowDimensions from "../../utils/useWindowDimensions"
import HeroVideoDesktop from "../../images/events_hero.gif"
import HeroVideoMobile from "../../images/events_hero_mobile.mp4"

export function Hero() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 768
  if (isMobileUp) {
    return (
      <div className="pm-container mx-auto px-6 mt-2">
        <h4 className="px-5 py-3  bg-indigo-50 text-sm w-fit mx-auto rounded-lg text-black">
          built for / <strong>Corporates</strong>
        </h4>
        <h1 className="text-center font-bold text-5xl mt-10 leading-tight text-black">
          Master the Art of Hosting
          <br /> Impactful Corporate Events
        </h1>
        <div className="rounded-3xl overflow-hidden mt-14 shadow-xl">
          <StaticImage
            placeholder="blurred"
            src="../../images/forevents.png"
            alt="logo"
            className="w-full"
            height={517}
          />
          <div className="p-12 flex items-center justify-between">
            <h4 className="text-black">Trusted by Global Brands like</h4>
            <div className="flex">
              <StaticImage
                className="w-28 mx-4"
                src="../../images/client-logos/21.png"
                alt="gitex"
                objectFit="contain"
              />
              <StaticImage
                className="w-16 mx-4"
                src="../../images/client-logos/5.png"
                alt="weva"
                objectFit="contain"
              />
              <StaticImage
                className="w-20 mx-4"
                src="../../images/client-logos/9.png"
                alt="weva"
                objectFit="contain"
              />
              <StaticImage
                className="w-28 mx-4"
                src="../../images/client-logos/18.png"
                alt="weva"
                objectFit="contain"
              />
              <StaticImage
                className="w-28 mx-4"
                src="../../images/client-logos/20.png"
                alt="weva"
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="pm-container mx-auto px-6 mt-20">
      <h4 className="px-5 py-3  bg-indigo-50 text-sm w-fit mx-auto rounded-lg text-black">
        built for / <strong>Corporates</strong>
      </h4>
      <h1 className="text-center font-bold text-3xl mt-10 leading-tight text-black">
        Master the Art of Hosting Impactful Corporate Events
      </h1>
      <div className="rounded-3xl overflow-hidden mt-14 shadow-xl">
        <StaticImage
          placeholder="blurred"
          src="../../images/forevents.png"
          alt="logo"
          className="w-full"
          height={517}
        />
        <div className="p-12">
          <h4 className="text-black text-center">
            Trusted by Global Brands like
          </h4>
          <div className="mt-5 flex flex-wrap items-center justify-center">
            <StaticImage
              className="w-28 mx-4"
              src="../../images/client-logos/21.png"
              alt="gitex"
              objectFit="contain"
            />
            <StaticImage
              className="w-16 m-3"
              src="../../images/client-logos/5.png"
              alt="weva"
              objectFit="contain"
            />
            <StaticImage
              className="w-20 m-3"
              src="../../images/client-logos/9.png"
              alt="weva"
              objectFit="contain"
            />
            <StaticImage
              className="w-28 m-3"
              src="../../images/client-logos/18.png"
              alt="weva"
              objectFit="contain"
            />
            <StaticImage
              className="w-28 m-3"
              src="../../images/client-logos/20.png"
              alt="weva"
              objectFit="contain"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
