import { Link } from "gatsby";
import { BlackArrowRight } from "../ds/Shapes";

export function Dropdown() {
  const caseStudies = [
    {
      to: "/step-conference",
      title: "Step Conference",
      description: "Creating a stellar global event that surpassed expectations.",
    },
    {
      to: "/umagine",
      title: "UmagineChennai",
      description: "Delivering an experience that goes beyond just networking.",
    },
    {
      to: "/saasboomi",
      title: "SaaSBOOMi Annual",
      description: "Tackling the hurdle of event photos management",
    },
    {
      to: "/nasscom",
      title: "Nasscom GCC Conclave",
      description: "Empowering sponsor-audience engagement ",
    },
    {
      to: "/wowawards",
      title: "Wow Awards",
      description: "Leveraging the power of instant delivery to create an impact",
    },
    {
      to: "/showsofindia",
      title: "Shows of India",
      description: "Merging creativity and technology to craft an impactful event experience",
    },
    {
      to: "/asiaberlin",
      title: "Asia Berlin",
      description: "A streamlined and innovative event marketing solution for ABS2023",
    },
    {
      to: "/aioc",
      title: "AIOC",
      description: "Resolving DAM Challenges for an organisation of ophthalmologists",
    },
    {
      to: "/real-estate-development-summit",
      title: "Real Estate Development Summit",
      description: "Simplifying interactions for enhanced UGC by real estate professionals.",
    },
    {
      to: "/mercedes-benz",
      title: "Coastal Star - Mercedes Benz",
      description: "Elevating event standards to align with brand values.",
    },
    {
      to: "/jsconf",
      title: "JSConf India",
      description: "Creating connections through digital tools for the tech community.",
    },
    {
      to: "/yuva-galam",
      title: "Yuva Galam Padayatra",
      description: "Learn how Nara Lokesh's Yuvagalam Padayatra used Premagic to drive TDP's success.",
    },
    {
      to: "/gtech",
      title: "GTECH Kerala Marathon",
      description: "Discover How Premagic Powered GTECH Kerala Marathon 2024 with AI-Driven Photo Distribution.",
    },
  ];

  return (
    <div className={`shadow-2xl border border-slate-100 hidden group-hover:block absolute top-20 mt-2 -left-40 -right-40 bg-white z-10 overflow-hidden transition duration-300 ease-in-out`}>
    <div className="container mx-auto">
      <div className='p-8'>
        <div className="flex space-between -ml-4">
          {Array.from({ length: 3 }).map((_, colIndex) => (
            <div key={colIndex} className="w-1/3">
              {caseStudies.slice(colIndex * 5, colIndex * 5 + 5).map((caseStudy, index) => (
                <Link key={index} className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to={`/case-study${caseStudy.to}`}>
                  <h4 className="text-base text-black font-semibold mb-2">{caseStudy.title}</h4>
                  <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">{caseStudy.description}</p>
                </Link>
              ))}
            </div>
          ))}
        </div>
        <Link to="/case-study/" className='flex items-center py-2 block mt-3'>
          <span className='mr-2 text-sm font-semibold text-black'>View all</span>
          <BlackArrowRight className='transition delay-100 duration-300 ease-in-out' />
        </Link>
      </div>
    </div>
  </div>
);
}

