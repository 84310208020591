import React from "react"
import Layout from "../../../components/Layout"
import Header from "../../../components/Header"
import CaseStudy, {
  CASE_STUDY_TYPES,
} from "../../../components/case-study/CaseStudy"
import ThumbnailImage from "../../../images/thumbnails/umagine_case_study_thumb.png"

export function Index() {
  return (
    <Layout
      title="Learn how Premagic helped in UmagineChennai's grand success"
      description="Learn how Premagic helped in UmagineChennai's grand success"
      metaImage={ThumbnailImage}
    >
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.UMAGINE} />
    </Layout>
  )
}

export default Index
