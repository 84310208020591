import React from "react"
import Layout from "../../../components/Layout"
import Header from "../../../components/Header"
import CaseStudy, {
  CASE_STUDY_TYPES,
} from "../../../components/case-study/CaseStudy"
import ThumbnailImage from "../../../images/thumbnails/asiaberlin.png"

export function Index() {
  return (
    <Layout
      title="Explore how AsiaBerlin transformed the ABS2023 experience with Premagic"
      description="Explore how AsiaBerlin transformed the ABS2023 experience with Premagic"
      metaImage={ThumbnailImage}
    >
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.ASIABERLIN} />
    </Layout>
  )
}

export default Index
