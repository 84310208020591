import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import Header from "../components/Header"
import Layout from "../components/Layout"
import { formGroup, input } from "../ds/form.module.css"
import { btn, btnPrimary } from "../ds/button.module.css"
import { animationBounceLeft } from "../ds/animation.module.css"
import { ArrowDownWhite, WhiteArrowRight } from "../ds/Shapes"
import { COUNTRY_CODES } from "../utils/CountryCodeUtils"
import { LayoutContext } from "../components/layoutContext"
import { getFormData, scrollToElement } from "../utils/BrowserUtils"
import { post } from "../utils/FetchUtils"

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3"

function CountryCodeSelector() {
  const data = useContext(LayoutContext)
  const [showDropdown, toggleDropdown] = useState(false)
  const wrapperRef = useRef(null)
  const defaultCountryCode = COUNTRY_CODES.find(
    item => item.alpha2Code === (data.userLocation?.country || "IN")
  )
  const [countryCode, setCountryCode] = useState(defaultCountryCode?.dial_code)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleDropdown(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  function onFocus() {
    toggleDropdown(true)
  }

  useOutsideAlerter(wrapperRef)

  return (
    <div className="w-36 relative" key={defaultCountryCode?.dial_code}>
      <input
        className={`${input} appearance-none`}
        value={countryCode}
        type="text"
        name="countryCode"
        onFocus={onFocus}
      />
      {showDropdown && (
        <div
          className="shadow-xl border-xl bg-white p-5 w-60 h-96 absolute rounded-xl -top-40 z-10 overflow-scroll"
          ref={wrapperRef}
        >
          {COUNTRY_CODES.map(item => (
            <p
              className="text-sm py-2 px-2 font-semibold cursor-pointer hover:bg-zinc-100 rounded-xl"
              onClick={() => {
                setCountryCode(item.dial_code)
                toggleDropdown(false)
              }}
            >{`${item.dial_code} - ${item.name.substring(0, 10)}`}</p>
          ))}
        </div>
      )}
    </div>
  )
}

export function Gdpr() {
  const [isLoading, setLoading] = useState(false)
  const [error, setErrors] = useState(null)
  const [checked, setChecked] = useState(false)

  const [token, setToken] = useState("")

  const { executeRecaptcha } = useGoogleReCaptcha()

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available")
      return
    }

    const recaptchaToken = await executeRecaptcha("LOGIN")
    setToken(recaptchaToken)

    // console.log(recaptchaToken);
    // Do whatever you want with the token
  }, [executeRecaptcha])

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  function handleFormSubmit(e) {
    e.preventDefault()
    // grecaptcha.enterprise.ready(async () => {
    //   token = await grecaptcha.enterprise.execute('6LeYUwIqAAAAANRz5IEkpHGFmPzk5iczl6rjK-o7', {action: 'LOGIN'});
    //   console.log(token);
    // });
    const data = getFormData(e)

    const phonereg = /^\d{9,13}$/

    const isProd = process.env.NODE_ENV === "production"

    const API_ROOT = isProd
      ? "https://api.premagic.com"
      : "http://localhost:5000"

    if (
      data.name.trim().length &&
      data.phone.replaceAll(" ", "").match(phonereg) !== null
    ) {
      setErrors(null)
      setLoading(true)
      // console.log(data);

      const payload = {
        name: data.name,
        email: data.email,
        phone: `${data.countryCode}${data.phone}`,
        request_type: data.request_type,
        message: data.message,
        token,
        recaptcha_action: "LOGIN",
      }

      post(`${API_ROOT}/privacy/requests/`, payload)
        .then(res => {
          console.log(res)
          setLoading(false)
          alert("Request completed successfully")
        })
        .catch(err => {
          alert("Request failed")
          setLoading(false)
        })

      return
    }
    setErrors("Please check the details you have provided")
  }
  return (
    <Layout title="GDPR - Premagic">
      <Header />
      <h1 className="relative z-10 text-black text-center mt-20 font-semibold">
        Premagic and GDPR Compliance
      </h1>
      <p className="relative z-10 text-black text-center mt-5 font-semibold">
        Updated 2nd January 2023
      </p>
      <div className="relative z-10 md:w-[800px] mx-auto mt-20 md:mt-40 px-5 md:px-0">
        <h2 className="text-black font-semibold text-4xl mb-5">Overview</h2>
        <p className="text-gray-600">
          Premagic is fully committed to protecting consumer privacy and the
          platform is compliant with government legislation around the world,
          including GDPR (
          <a href="https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32016R0679">
            General Data Protection Regulation
          </a>
          ). You can read our full commitment to privacy here:{" "}
          <a href="https://blog.premagic.com/privacy/">
            Premagic Privacy Policy
          </a>
          .
        </p>
        <p className="text-gray-600 mt-5">
          To fully enjoy the Premagic experience, we require certain personal
          information. When you choose to share information with us, we collect
          and use it to operate our services. Under laws like GDPR, you have the
          right to access this information. Below is an overview of how Premagic
          handles these requests.
        </p>

        <h2 className="text-black font-semibold text-4xl mt-20 mb-5">
          Request to change personal information
        </h2>
        <p className="text-gray-600">
          A Premagic user may want to change personal information associated
          with their account. In order to change information like username,
          phone number, email address, please fill out the form below and select
          “Change Personal Information” from the drop-down. A Premagic
          representative will contact you within 48 hours of receipt of the
          request.
        </p>

        <h2 className="text-black font-semibold text-4xl mt-20 mb-5">
          Request to access personal data
        </h2>
        <p className="text-gray-600">
          A user has the right to access the personal data that is stored on the
          Premagic platform. To request personal information, please fill out
          the form below and select “Collect Personal Data for Export”. Your
          data will be collected and you will be notified via email when your
          personal data report is ready for download. This process may take up
          to 30 days to complete.
        </p>

        <h2 className="text-black font-semibold text-4xl mt-20 mb-5">
          Request to erase all personal data
        </h2>
        <p className="text-gray-600">
          A user also has the right to request an erasure of all personal data
          that is stored on the Premagic platform. Please note, this procedure
          is not reversible and once it is complete, your Premagic account, all
          of the personal information and any images stored on the platform–both
          professional and personal–will be deleted. <br />
          <br />
          To request complete erasure of all personal information, please fill
          out the form below and select “Erase All Personal Data from Premagic.”
          Your account will be frozen and you will be contacted by a Premagic
          representative within 48 hours. Upon confirmation of request, your
          personal data and images will be deleted from the platform. Again,
          this process is not reversible. The data erasure process may take up
          to 30 days to complete.
        </p>

        <div className="md:w-5/6 mx-auto mt-20">
          <div
            className="px-10 md:px-20 py-10 rounded-3xl"
            style={{ backgroundColor: "#7251F4" }}
          >
            <h1 className="text-white font-semibold text-xl mb-8 mt-5">
              Form for submitting request
            </h1>

            <form onSubmit={handleFormSubmit} id="account-lead-events">
              {error && (
                <div
                  className="js-error bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-10"
                  role="alert"
                >
                  <strong className="font-bold">Oops!</strong>{" "}
                  <span className="block sm:inline">{error}</span>
                </div>
              )}

              <div className={formGroup}>
                <p className="text-white font-semibold text-base mb-1">
                  Ful name
                </p>
                <input
                  required
                  type="text"
                  name="name"
                  autoFocus
                  placeholder=""
                  className={input}
                />
              </div>
              <div className={`${formGroup} mt-4`}>
                <p className="text-white font-semibold text-base mb-1">Email</p>
                <input
                  required
                  type="email"
                  name="email"
                  placeholder="eg. david@workemail.com"
                  className={input}
                />
              </div>
              <div className="mt-4">
                <p className="text-white font-semibold text-base mb-1">
                  Phone number
                </p>
                <div className="flex ">
                  <CountryCodeSelector />
                  <div className="px-2" />
                  <input
                    required
                    type="tel"
                    name="phone"
                    id="input-number"
                    placeholder="eg. 9XX XXX XXXX"
                    minLength={8}
                    maxLength={13}
                    className={input}
                  />
                </div>
              </div>
              {/* <div className="text-xs my-4 text-gray-500 flex items-center">
                            <WhatsAppIcon />
                            <div className='ml-2'>
                                We will be sending updates via WhatsApp
                            </div>
                        </div> */}

              <div className={`${formGroup} mt-4 relative`}>
                <select
                  name="request_type"
                  placeholder="Please choose your request type from the list"
                  className={`${input} appearance-none`}
                  defaultValue=""
                  required
                >
                  <option disabled hidden value="">
                    Please choose your request type from the list
                  </option>
                  <option value="change_personal_information">
                    Change personal information
                  </option>
                  <option value="collect_personal_data_for_export">
                    Collect personal data for export
                  </option>
                  <option value="erase_all_data_from_premagic">
                    Erase all data from premagic
                  </option>
                </select>
                <div className="absolute top-1/2 right-5 -translate-y-1/2 opacity-60">
                  <ArrowDownWhite />
                </div>
              </div>

              <div className={`${formGroup} mt-4`}>
                <p className="text-white font-semibold text-base mb-1">
                  Message ( Optional )
                </p>
                <textarea name="message" placeholder="" className={input} />
              </div>

              {/* <div className='text-white mt-5 text-sm flex items-start'>
                            <input className='input mr-2 mt-1' type='checkbox' onChange={(e) => setChecked(e.target.checked)} />
                            <div>
                                I agree to Premagic's{' '}
                                <a href="https://blog.premagic.com/terms/" className='text-white font-bold' target="_blank" rel="noreferrer">
                                Terms of Service
                                </a>{' '}
                                and{' '}
                                <a href="https://blog.premagic.com/privacy/" className='text-white font-bold' target="_blank" rel="noreferrer">
                                Privacy Policy
                                </a>.
                            </div>
                        </div> */}
              <button
                id="submit_button"
                className={`${btn} ${btnPrimary} bg-white mt-10`}
                type="submit"
              >
                {isLoading ? `Loading...` : `Submit`}
                <div className={`${animationBounceLeft} ml-5`}>
                  <WhiteArrowRight />
                </div>
              </button>
            </form>
          </div>
        </div>
        <h2 className="text-black font-semibold text-4xl mt-20 mb-5">
          Other ways to contact Premagic
        </h2>
        <p className="text-gray-600">
          If you have any questions about your personal data or if you want to
          exercise your rights regarding your personal data please contact us by
          any of the following means:
        </p>
        <p className="text-gray-600 mt-5">
          Customer Support:{" "}
          <a
            className="font-semibold text-black"
            href="mailto:support@premagic.com?Subject=Help"
          >
            click here
          </a>{" "}
          to contact Premagic Support
        </p>
        <p className="text-gray-600 mt-5">
          For security concerns or to report any vulnerabilities, please reach
          out to us at{" "}
          <a
            className="font-semibold text-black"
            href="mailto:security@premagic.com"
          >
            security@premagic.com
          </a>{" "}
        </p>
        <p className="text-gray-600 mt-5">
          Call us:{" "}
          <a className="font-semibold text-black" href="tel:‭+918106176986">
            +91 8106176986
          </a>
        </p>
        <p className="text-gray-600 mt-5">Write to us:</p>
        <p className="text-gray-600 mt-5">Premagic</p>
        <p className="text-gray-600 mt-1">
          Wellbeing Studios Pvt. Ltd <br />
          Kerala Technology Innovation Zone <br />
          Kinfra Hi-Tech Park, HMT Colony P.O.
          <br /> Kalamassery, HMT Colony, North Kalamassery, <br />
          HMT Kalamassery, Kochi, Kerala 683503
        </p>
        <p></p>
      </div>
      <div className="py-20" />
    </Layout>
  )
}

export function Index() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeYUwIqAAAAANRz5IEkpHGFmPzk5iczl6rjK-o7">
      <Gdpr />
    </GoogleReCaptchaProvider>
  )
}

export default Index
