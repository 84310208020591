import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Flickity from "react-flickity-component"
import useWindowDimensions from "../../utils/useWindowDimensions"
import { Link } from "gatsby"
import { WhiteArrowRight } from "../../ds/Shapes"
import { btnSmall } from "../../ds/button.module.css"

const featuresData = [
  {
    title: "Boost Your Sponsorship Visibility",
    desciption:
      "Make your sponsors shine with in-gallery ad placements, watermarked logos on every photo, and detailed analytics to track reach and engagement.",
    bgColor: "#FFF1EF",
    titleColor: "#E15D4B",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_1.png"
        alt="client magic motion media"
      />
    ),
    btnText: "Get a Demo",
  },
  {
    title: "Tailor-Made Galleries for Your Brand",
    desciption: `Customize your gallery with your brand's colours, fonts and watermarks on each photo to create a unique look that represents your brand.`,
    bgColor: "#EAFFF8",
    titleColor: "#109A6D",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_2.png"
        alt="client magic motion media"
      />
    ),
    btnText: "Get Started",
  },
  {
    title: "Engage Your Audience with Custom Content",
    desciption:
      "Take audience engagement up a notch by sharing custom images or video ads directly in your gallery with a clear CTA to drive leads and action.",
    bgColor: "#FFF7E8",
    titleColor: "#613F00",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_3.png"
        alt="client magic motion media"
      />
    ),
    btnText: "Create an Account",
  },
]

export function Features() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"

  const flickityOptions = {
    // autoPlay: 2000,
    // wrapAround: true,
    // selectedAttraction: 0.01,
    friction: 0.2,
    cellAlign: "left",
    contain: true,
    // contain: true,
    // pageDots: true,
    // prevNextButtons: true,
    // pauseAutoPlayOnHover: false
  }

  return (
    <section>
      <div
        className="mx-auto mt-10 mb-20 md:mb-20"
        style={{ width: containerWidth }}
      >
        <h2 className="hidden md:block text-black text-5xl font-bold text-center leading-tight md:leading-tight relative z-20">
          But wait, there’s
          <div className="relative mx-2 inline z-10">
            <div className="relative inline-block z-5">
              <span
                style={{ backgroundColor: "#61F2C2" }}
                className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-1"
              ></span>
              <div className="relative z-2">more!</div>
            </div>
          </div>
        </h2>
        <h2 className="md:hidden text-black text-3xl font-bold text-center leading-tight md:leading-tight relative z-20">
          But wait, there’s
          <div className="relative mx-2 inline z-10">
            <div className="relative inline-block z-5">
              <span
                style={{ backgroundColor: "#61F2C2" }}
                className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-0"
              ></span>
              <div className="relative z-2">more!</div>
            </div>
          </div>
        </h2>
        <p className="mt-10 mb-14 text-base md:text-lg opacity-60 text-center px-5 md:px-0">
          We’re more than just a photo delivery platform
        </p>
        <div className="relative">
          <div className="">
            <div className="md:flex">
              {featuresData.map(item => (
                <div className="px-3 my-10">
                  <div
                    className="rounded-xl h-full flex flex-col"
                    style={{ backgroundColor: item.bgColor }}
                  >
                    <div className="p-10 pb-0">
                      <h2
                        className="font-bold text-2xl text-black"
                        style={{ color: item.titleColor }}
                      >
                        {item.title}
                      </h2>
                      <p className="text-sm mt-5">{item.desciption}</p>
                      <Link
                        to="/request-a-demo/"
                        className=" flex mt-5 items-center text-xs w-max group p-2 rounded-lg"
                        style={{ backgroundColor: item.titleColor }}
                      >
                        <span className="mr-4 ml-2 text-white font-semibold">
                          {item.btnText}
                        </span>
                        <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
                      </Link>
                    </div>
                    <div className="rounded-xl overflow-hidden text-right mt-auto">
                      {item.image}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
