import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  btn,
  btnDefault,
  playButton,
  playButtonIcon,
} from "../../ds/button.module.css"
import { ArrowRightWithGradient } from "../../ds/Shapes"
import { cardBorderBottomBlue } from "../../ds/card.module.css"
import { chatArrowPink, chatArrowPurple } from "../../ds/shapes.module.css"
import useWindowDimensions from "../../utils/useWindowDimensions"
import {
  CheckIconPink,
  CheckIconViolet,
  CheckIconBrown,
  CheckIconPurple,
} from "../../ds/SvgIcons"
import Purplekqoute from "../../images/icons/purpleqoute.svg"
import Pinkqoute from "../../images/icons/pinkqoute.svg"

const mainFeatures = [
  {
    image: <StaticImage src="../../images/main-features/001.png" alt="" />,
    title: "Accelerate sales pipeline and prove event ROI",
    description:
      "Your events are under increased pressure to drive pipeline and produce other measurable business results.",
    icon: <img src={Pinkqoute} alt="" />,
    message:
      "Your events are under increased pressure to drive pipeline and produce other measurable business results.",
    userIcon: (
      <StaticImage
        class="rounded-full"
        src="../../images/use_face.png"
        alt=""
        width={48}
      />
    ),
    arrow: <div className={`${chatArrowPink}`}></div>,
    name: "Nadeem Nazar",
    designation: "Executive Staff, Nasscomm",
    color: "#FFF1F4",
  },
  {
    image: <StaticImage src="../../images/main-features/002.png" alt="" />,
    title: "Accelerate sales pipeline and prove event ROI",
    description:
      "Your events are under increased pressure to drive pipeline and produce other measurable business results.",
    icon: <img src={Purplekqoute} alt="" />,
    message:
      "Your events are under increased pressure to drive pipeline and produce other measurable business results.",
    userIcon: (
      <StaticImage
        class="rounded-full"
        src="../../images/use_face.png"
        alt=""
        width={48}
      />
    ),
    arrow: <div className={`${chatArrowPurple}`}></div>,
    name: "Nadeem Nazar",
    designation: "Executive Staff, Nasscomm",
    color: "#F6F0FF",
  },
]

export function MainFeaturesSectionSecondary() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 768
  if (isMobileUp) {
    return (
      <div className="pm-container mx-auto px-6 my-24">
        <h1 className="text-center font-bold text-5xl mt-10 leading-tight text-black leading-snug">
          The right platform you would need
          <br /> for corporate events
        </h1>
        <p className="text-black text-center mt-10 mb-10">
          Supercharging your business growth to new heights
        </p>
        {mainFeatures.map((item, idx) => (
          <div
            className={`flex my-20 ${idx % 2 === 0 ? "" : "flex-row-reverse"}`}
          >
            <div className="w-1/2">{item.image}</div>
            <div
              className={`w-1/2 flex flex-col justify-center ${
                idx % 2 === 0 ? "pl-20" : "pr-20"
              }`}
            >
              <h2 className="text-black text-3xl font-semibold leading-snug">
                {item.title}
              </h2>
              <p className="text-base text-black my-10">{item.description}</p>
              <div
                className="p-10 rounded-xl relative mt-8"
                style={{ backgroundColor: `${item.color}` }}
              >
                {item.arrow}
                <div className="absolute left-7 -top-2">{item.icon}</div>
                <p className="text-sm">{item.message}</p>
                <div className="flex mt-5">
                  <div>{item.userIcon}</div>
                  <div className="ml-3">
                    <h4 className="text-sm font-bold text-black mt-1">
                      {item.name}
                    </h4>
                    <p className="text-xs font-normal mt-1">
                      {item.designation}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="pm-container mx-auto px-6 my-24">
      <h1 className="text-center font-bold text-3xl mt-10 leading-tight text-black">
        The right platform <br />
        you would need for corporate events
      </h1>
      <p className="text-black text-base text-center mt-10 mb-10">
        Supercharging your business <br />
        growth to new heights
      </p>
      {mainFeatures.map((item, idx) => (
        <div className="my-20">
          <div className="mb-10 overflow-hidden rounded-2xl">{item.image}</div>
          <div className="pt-2">
            <h2 className="text-black text-xl font-semibold leading-snug pr-5">
              {item.title}
            </h2>
            <p className="text-base text-black pr-5">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MainFeaturesSectionSecondary
