import React from "react"

import Layout from "../components/Layout"
import Header from "../components/Header"
import { gradientLightPink } from "../ds/gradients.module.css"
import { Link } from "gatsby"
import { URLifyText } from "../utils/BrowserUtils"

const faqs = [
  {
    title: "How to download Premagic?",
    ans: (
      <p>
        Visit our website www.premagic.com. Click the download button. Provide
        your Name, Email & Phone number. You will receive a welcome email from
        Premagic with download link & a License key to use Premagic.
      </p>
    ),
  },
  {
    title: "Is it a website?",
    ans: (
      <p>
        Premagic app is not a website. Its a Desktop app which has to be
        installed in your desktop or laptop ( Mac or Windows )
      </p>
    ),
  },
  {
    title: "How to install Premagic?",
    ans: (
      <p>
        Open the welcome email from your desktop or laptop & click the download
        link in It. Once the download completes, double click the setup file &
        run it for installing.
      </p>
    ),
  },
  {
    title: "Is it a mobile app ?",
    ans: (
      <p>
        Premagic is not a mobile app.So you cannot use it from your mobile
        phones. It's a desktop app.
      </p>
    ),
  },

  {
    title: "In which drive should I install Premagic?",
    ans: (
      <p>
        Choose a drive with maximum FREE space. We recommend not to install in
        your C Drive
      </p>
    ),
  },

  { title: "Do I need a License key to try Premagic?", ans: <p>Yes. </p> },

  {
    title: "Do I need an internet connection for using Premagic?",
    ans: <p>Yes </p>,
  },

  { title: "Is there a trial version to try Premagic?", ans: <p>Yes. </p> },

  {
    title: "What is a project?",
    ans: (
      <p>
        A project means an event. Eg: Rahul & Nina Wedding, which has a client
        associated with it.
      </p>
    ),
  },

  {
    title: "Is it possible to create folders inside projects?",
    ans: (
      <p>
        Yes. you can create multiple folders inside each project. Eg: Wedding is
        a project. Engagement, Prewedding, Outdoor, Haldi, Mehendi etc can be
        the folders
      </p>
    ),
  },

  {
    title: "What if there are multiple camera/cameraman involved in the event?",
    ans: (
      <p>
        Use multiple folders under each project for the photos in each camera.
      </p>
    ),
  },

  {
    title:
      "Do I need to create different projects for each function under a particular project?",
    ans: (
      <p>
        No. Use seperate projects only if its a different event having different
        client. For the Same client, use folders inside that particular project.
      </p>
    ),
  },

  {
    title: "How much time it takes to upload photos in Premagic?",
    ans: <p>Usually it takes 2-3 minutes for 1GB Data </p>,
  },

  {
    title: "Does Premagic compresses the original photos?",
    ans: <p>Yes. But without losing the quality. </p>,
  },

  {
    title: "Will it consume a lot of my internet data?",
    ans: (
      <p>
        No.Since we upload only the compressed photos, it won’t consume much
        data.
      </p>
    ),
  },

  {
    title: "What if the internet connections fails?",
    ans: (
      <p>
        Uploading is a background task. It will stop uploading if the internet
        connection fails & automatically start uploading again once the internet
        connection is successful.
      </p>
    ),
  },

  {
    title: "Do I need to wait for the upload to complete?",
    ans: (
      <p>
        No you don’t need to wait. Upload will happen automatically & the client
        receives the photos once the upload complete. Just minimise the app &
        leave your seat. Ensure the system is not shut down.
      </p>
    ),
  },

  {
    title: "Can I create more folders before the upload completes?",
    ans: <p>Yes. </p>,
  },

  {
    title: "Will there be a quality loss for the photos uploaded in Premagic?",
    ans: <p>No. </p>,
  },

  { title: "Is it secure to share photos using Premagic?", ans: <p>Yes. </p> },

  {
    title:
      "Is there any security measures to ensure the privacy of my clients photos?",
    ans: (
      <p>
        Yes. We use end to end encryption. No one from Premagic team or outside
        can see the photos.
      </p>
    ),
  },

  {
    title: "Is it password protected?",
    ans: <p>Yes. Only the person with the password can select the photos. </p>,
  },

  {
    title: "How does the client receives photos shared using Premagic?",
    ans: (
      <p>
        Client receives the photos as a compressed smart link in their email.
        They can open & View from their mobile phones just like they view photos
        in their gallery
      </p>
    ),
  },

  {
    title:
      "Does the client requires an active internet connection to see & select photos.",
    ans: <p>Yes. </p>,
  },

  {
    title: "Does the client get original quality photos?",
    ans: (
      <p>
        No. All the original photos remains in your computer. For selection
        purpose client gets only a compressed quality photo to ensure smooth
        selection. It won’t have enough quality to print
      </p>
    ),
  },

  {
    title: "Is it possible for the clients to download photos from Premagic?",
    ans: <p>Yes. But the photographer have a control to block it. </p>,
  },

  {
    title:
      "Is it possible for the clients to share photos with their friends & family?",
    ans: <p>Yes. </p>,
  },

  {
    title: "Can I edit the email ID for a particular project?",
    ans: <p>No </p>,
  },

  {
    title: "What if I typed a wrong email ID of the client?",
    ans: (
      <p>
        Premagic send a copy of every client email to your registered email ID.
        Incase you gave a wrong email ID, then please check your registered
        email, find that particular client email & forward the same mail to the
        correct email ID.
      </p>
    ),
  },

  {
    title: "Can I share photos to multiple email ID?",
    ans: (
      <p>No. Instead forward the client email from your registered email ID.</p>
    ),
  },

  {
    title: "Can I share photos to client’s whatsapp number?",
    ans: <p>Yes. Please check the whatsapp link under each project. </p>,
  },

  {
    title: "How can I share the projects with clients family or relatives?",
    ans: (
      <p>Yes. Either forward the client email or share the whatsapp link. </p>
    ),
  },

  {
    title: "Does the client requires a mobile app for using Premagic?",
    ans: <p>No. They don’t need to download any mobile apps. </p>,
  },

  {
    title: "Is it possible for the client to select photos from mobile phones?",
    ans: (
      <p>
        Yes. They can do the selection from mobile phones, laptops & desktops.
      </p>
    ),
  },

  {
    title:
      "Is it possible for the client’s family & relatives to select photos?",
    ans: <p>Yes. </p>,
  },

  {
    title:
      "Is it possible for the bride and groom to select photos from multiple location & devices?",
    ans: <p>Yes. </p>,
  },

  {
    title:
      "My drive is consuming more space once i started using Premagic. Why?",
    ans: (
      <p>
        Premagic works on the proxy model. However, it creates an extra backup
        for each project which can be erased once the project is completed & the
        album is delivered.
      </p>
    ),
  },

  { title: "Can I change the installation path?", ans: <p>Yes. </p> },

  {
    title: "Can I access the previous projects If I format my computer?",
    ans: <p>Yes. </p>,
  },

  {
    title: "How much is the cost of Premagic?",
    ans: (
      <p>
        It depends on the number of projects you take. Please complete your
        trial plan & upgrade to see the different plans
      </p>
    ),
  },
  {
    title: "Is there a validity period for projects?",
    ans: <p>No. Its unlimited. </p>,
  },

  {
    title: "How long the photos will remain in the cloud backup?",
    ans: <p>6 months. </p>,
  },
]

export default function FaqPage() {
  return (
    <Layout
      title="FAQ's | Have a question on how to use Premagic ? We’ve got answers."
      description="Find answers to commonly asked questions about Premagic.  Is there a trial version to try Premagic? Yes."
    >
      <div itemScope itemType="https://schema.org/FAQPage">
        <div className={gradientLightPink}>
          <Header />

          <div className="pm-container">
            <h1 className="py-20 font-bold">
              FAQ's{" "}
              <p className="font-normal text-sm text-gray-500">
                Frequently asked questions
              </p>
            </h1>
          </div>
        </div>
        <div className="pm-container">
          {faqs.map(faq => {
            const urlHash = `#${URLifyText(faq.title)}`

            return (
              <div
                className="mb-16"
                key={urlHash}
                id={urlHash}
                itemScope
                itemProp="mainEntity"
                itemType="https://schema.org/Question"
              >
                <Link to={urlHash}>
                  <h2 className="text-lg font-bold" itemProp="name">
                    {faq.title}
                  </h2>
                </Link>
                <div
                  itemScope
                  itemProp="acceptedAnswer"
                  itemType="https://schema.org/Answer"
                  className="text-gray-600"
                >
                  <div itemProp="text">{faq.ans}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}
