import { StaticImage } from "gatsby-plugin-image";

import LinkedInIcon from '../../images/case-study/ugc/linkedin.svg';
import icon1 from '../../images/svg/1.svg';
import icon2 from '../../images/svg/2.svg';
import icon3 from '../../images/svg/3.svg';
import icon4 from '../../images/svg/4.svg';

const stepConferenceData =  {
  color: '#d9e2ff',
  heroTitle: `Discover how Step 2024 turned attendees into advocates with Premagic`,
  heroImage: <StaticImage src="../../images/case-study/stepconference/1.png" alt="" />,
  metrics: {
    title: 'Background',
    content: `Step Conference, Dubai's annual technology and startup festival, attracts over 8,000 attendees, including investors, industry experts, and startup founders, over a two-day period. With 340 speakers across 4 stages, 110+ investment companies engaging in pre-curated meetings, 50+ exhibitors, and 400+ startups showcasing their visions, Step Conference 2024 was an event of unprecedented scale.`,
    host: 'Step',
    organizer: 'Dana Sakr, Marketing Manager, Step Conference.',
    industry: 'Technology and Startup Events'
  },
  images: [
    {
      img: <StaticImage src="../../images/case-study/stepconference/banner1.png" alt="" />
    },
    {
      img: <StaticImage src="../../images/case-study/stepconference/banner2.png" alt="" />
    },
  ],
  gallery: <StaticImage src="../../images/case-study/stepconference/gallery.png" alt="" />,
  caseStudyData: [
    {
      title: 'The Challenge',
      info1: `Unlike other tech events, Step is an experience - it’s more laid-back and a great place for organic networking opportunities.  Dana Sakr, Step’s marketing manager and her team aimed to create a memorable experience that goes beyond typical tech events.`,
      testimonial: {
        img: <StaticImage src="../../images/case-study/stepconference/avatar.png" alt="" />,
        message: `<i>"From a marketing perspective, one thing we're really focused on is keeping our audience engaged even beyond the conference dates, and this is where Premagic really helped us."</i>`,
        name: 'Dana Sakr',
        info: 'Marketing Manager <br/> Step Conference'
      },
      video: 'https://www.youtube.com/embed/WkgWyDwy3lw?si=M8xpRU3-qwloS50W',
      info2:`Step’s marketing team needed a way to convert attendees into active content creators and share experiences seamlessly across social channels, extending the reach and longevity of their event’s impact. <br /><br /> Dana and her team were keen to explore organic marketing solutions to achieve the same. “I strongly believe that organic marketing is essential for building a strong online presence and long-term relationships with our audience," states Dana. <br /><br /> The key was to make shareable content easily available to all the attendees. Like the event photographs. But when it comes to photo distribution, managing and distributing high-quality content for such a large crowd was time-intensive. Without a streamlined process, Step’s team risked missing an opportunity for increased social engagement and organic reach, which are essential in the events space. <br /><br /> "We have photographers on the ground taking pictures of speakers, investors, and startups, but it’s challenging to get these photos to attendees. Premagic made this seamless.”`,
    },
    {
      title: 'The Solution',
      info1: `Step turned to Premagic to address the challenges they faced regarding their approach to attendee engagement and organic marketing. From the outset, Dana recognized the role that Premagic’s event tech solutions could play in keeping attendees engaged throughout the event's lifecycle. </br></br> With Premagic, Step’s team gained a user-friendly, streamlined system that allowed them to: <br /><br /> <b>Seamless photo-sharing:</b> Premagic’s platform enabled Step’s team to distribute the event photos among attendees, speakers, and partners seamlessly. "Premagic is easy to use—uploading photos is as simple as drag and drop, and the team was available for all our questions," stated Dana. The attendees could then quickly access and download their photos by submitting a selfie, making the process seamless and fast. <br /><br />`,
      testimonial: {
        img: <StaticImage src="../../images/case-study/stepconference/avatar.png" alt="" />,
        message: `<i>"Premagic has been crucial in converting <br /> our attendees into content creators."</i>`,
        name: 'Dana Sakr',
        info: 'Marketing Manager <br/> Step Conference'
      },
      info2: `<b>From photos to user-generated content:</b> Premagic helped attendees easily access and share their event photos, transforming them into active brand ambassadors. This simple step multiplied Step’s reach on social media as attendees organically shared their experiences online.<br /><br /> With features that keep audiences connected beyond the event dates, Premagic extended Step’s engagement window, maintaining the event's momentum and maximizing its impact.<br/><br/> Throughout the process, Premagic’s dedicated customer support was a standout feature. A dedicated account manager guided the Step team from initial setup to event execution, including library creation, gallery design, and on-ground support. “The Premagic team made the whole process smooth and enjoyable, with excellent hands-on support,” Dana shared.`,

    },
    {
      title: 'The Results',
      info1: `Step Conference achieved a fantastic attendee retention rate compared to the previous year's event. As Dana said, "This is validation that we're putting on an event that people want to come back to." <br/><br/>With Premagic’s tools in place, Step Conference also saw a significant boost in post-event engagement. Attendees quickly accessed and shared their photos, generating additional organic content and expanding Step’s online presence beyond the event itself.`,
      testimonial: {
        img: <StaticImage src="../../images/case-study/stepconference/avatar.png" alt="" />,
        message: `<i>"Premagic simplified attendee engagement and extended the event experience beyond the physical event itself."</i>`,
        name: 'Dana Sakr',
        info: 'Marketing Manager <br/> Step Conference'
      },
    
      gridViewData: [{
        icon: <img src={icon1} alt="" />,
        title: 'Enhanced Attendee Engagement',
        description: `Attendees had quick, easy access to their photos and were encouraged to share memories on social media.`
      },
      {
        icon: <img src={icon2} alt="" />,
        title: 'Increased Organic Reach',
        description: `With organic content generated by attendees, Step Conference’s visibility grew significantly online.`

      },
      {
        icon: <img src={icon3} alt="" />,
        title: 'Enhanced Brand Visibility',
        description: `High-resolution, watermarked photos were delivered in sleek branded AI galleries, aligned with the GITEX aesthetic and featuring face-search capabilities for easy access.`

      },
      {
        icon: <img src={icon4} alt="" />,
        title: 'Streamlined Event Management',
        description: `Premagic’s user-friendly platform and exceptional customer support made the entire experience efficient and enjoyable for Step’s team.`

      }
      ],
      info2:`Premagic proved to be a valuable asset for Step Conference, simplifying photo-sharing, enhancing post-event engagement, and allowing the team to focus on what matters most: creating a memorable experience for attendees that continues well beyond the conference doors.`
    },
    {
      title: 'The Future',
      info1: `Dana expressed her enthusiasm for Premagic's role in the event's success.`,
      testimonial: {
        img: <StaticImage src="../../images/case-study/stepconference/avatar.png" alt="" />,
        message: `<i>"To my fellow marketers, Premagic can really boost your organic presence and engagement during and after the event. I’d highly recommend it for any event business.”</i>`,
        name: 'Dana Sakr',
        info: 'Marketing Manager <br/> Step Conference'
      },
      info2: `In addition, Step’s team found Premagic’s new features particularly exciting for furthering their engagement goals: <br/><br/>
      <b>Networking Module:</b> This feature enables attendees to connect with others they meet at the event on LinkedIn, helping them build meaningful, lasting connections beyond the app.<br/><br/> <b>Sparks:</b> Similar to Google Memories, Sparks resurfaces photos and videos post-event, reviving engagement and encouraging attendees to re-share their experiences.</br></br> <b>Event Marketing Posters:</b> Premagic’s customizable “I’m attending” or “I’m speaking” posters provided a valuable pre-event marketing tool, allowing attendees and speakers to promote their involvement effortlessly. Dana stated, “Premagic’s event marketing posters, like the ‘I’m attending’ ones, are a fantastic feature for pre-event promotion, especially at events like Step. <br/><br/>As the Step Conference team looks ahead to the next iteration of their flagship event, they are confident in their decision to integrate Premagic as a platform for driving attendee engagement and fostering organic marketing.`
    }
  ],
  UGCData: {
    hashtag: "#Step2024UGCHub",
    background: '#d9e2ff',
    data: [
      {
        image: <StaticImage src='../../images/case-study/ugc/stepconference/alper_post.png' alt="" className="mb-5" />,
        content: ` This week at the <span class="text-blue-700 font-semibold">#Step2024</span> Dubai conference I had the pleasure of being on a panel with Medea Nocentini and Caroline Guyot, moderated by Saqr Ereiqat. </br></br> We discussed the investment landscape in the impact and sustainability across the MENA region. I am happy to note that there have been significant developments in the impact investment landscape in the past few years, which is very promising for the future. Looking forward to witnessing more advancements in this region. </br></br> Big thanks to <span class="text-blue-700 font-semibold">Step</span> for organizing such insightful and well-curated panels.`,
        avatar: <StaticImage src='../../images/case-study/ugc/stepconference/alper.png' alt="" />,
        name: 'Alper Guven',
        designation: 'MBA Canditate at UPF Barcelona School of Management',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: 'pb-4'
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/stepconference/michael_post.png' alt="" className="mb-5" />,
        content: `I had a great time participating on the secondary panel at <span class="text-blue-700 font-semibold">#Step2024</span> Dubai this past week. As MENA matures, additional tools to facilitate the growth of the ecosystem will be needed and the opportunity for secondary investors will grow. Ion Pacific has participated in transactions in the region and will continue to search for additional opportunities </br></br> Thanks to <span class="text-blue-700 font-semibold">Shinez Chalabi</span> for facilitating the interesting discussion and to <span class="text-blue-700 font-semibold">Zuhair Shamma</span>, <span class="text-blue-700 font-semibold">Divyat Rungta</span> and <span class="text-blue-700 font-semibold">Vittal Ramakrishna</span> for making the topic come alive.`,
        avatar: <StaticImage src='../../images/case-study/ugc/stepconference/michael.png' alt="" />,
        name: 'Michael Joseph',
        designation: 'Founding Partner at Ion Pacific Limited',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: 'pb-4'
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/stepconference/inodash_post.png' alt="" className="mb-5" />,
        content: `We've thrilled to attend <span class="text-blue-700 font-semibold">Step</span> 2024 in Dubai last week! It was a great opportunity to explore innovative ideas, and pave the way for future collaborations. Here are the snapshots from our experience! 🌟`,
        avatar: <StaticImage src='../../images/case-study/ugc/stepconference/inodash.png' alt="" />,
        name: 'Inodash',
        designation: 'Build new businesses in a faster and cheaper.',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: ''
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/stepconference/javeria_post.png' alt="" className="mb-5" />,
        content: `Do you have an elevator pitch for your business? </br></br> At <span class="text-blue-700 font-semibold">Step</span>, founders pitched their startup ideas to investors in less than 3-5 minutes. Each start-up showcased the use of technology to solve problems that exist and persist daily. </br></br> A few pitches that stood out to me: </br></br> -<span class="text-blue-700 font-semibold">Musajjel مسجّل:</span> 📱 an AI-driven podcasting tool that seamlessly records and edits podcasts without involving any technical skills. With over 460 mn podcast listeners globally, there is massive potential in this industry. </br></br> -<span class="text-blue-700 font-semibold">My Park Buddy: 🚗</span> a mobile app that will locate the closest parking and entrance to your destination. We all the know the struggles of finding parking spots in fast paced cities like Dubai. So here’s one app that’s going to make lives much easier.  `,
        avatar: <StaticImage src='../../images/case-study/ugc/stepconference/javeria.png' alt="" />,
        name: 'Javeria Khalid',
        designation: 'Tech Journalist and Writer | Reported for CNN & Fast Company.',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: ''
      },
      {
        image: <StaticImage src='../../images/case-study/ugc/stepconference/clementine_post.png' alt="" className="mb-5" />,
        content: `<span class="text-blue-700 font-semibold">Step</span> Conference was yet again incredible, full of interesting people and ideas… It was great to talk (and learn) more about AI in education, crypto-philanthropy and making tech education accessible to all. Shout out to <span class="text-blue-700 font-semibold">Dr Sheen Gurrib FRSA</span> for moderating our EdTech panel and my fellow panelists <span class="text-blue-700 font-semibold">Nada Sayarh Phd,</span>,. and <span class="text-blue-700 font-semibold">Shaikha Alnuaimi</span> - very cool women!! </br></br> One startup you should check out is <span class="text-blue-700 font-semibold">Cappella</span>, whose app translates babies cries using AI up to 95% accuracy (vs average 30% accuracy among mothers) 🤯 </br></br> Thanks to <span class="text-blue-700 font-semibold">Marc Bou Mansour</span> for your support!!`,
        avatar: <StaticImage src='../../images/case-study/ugc/stepconference/clementine.png' alt="" />,
        name: 'Clementine Brown',
        designation: 'Co-Founder at CodeBrave | Deloitte Fast50 Impact Award',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: 'pb-5'
      },

      {
        image: <StaticImage src='../../images/case-study/ugc/stepconference/robert_post.png' alt="" className="mb-5" />,
        content: `It's wonderful to be involved again after two years, to witness the growth of startups and reconnect with people gaining a fresh grasp of new emerging trends at <span class="text-blue-700 font-semibold">#Step2024</span>`,
        avatar: <StaticImage src='../../images/case-study/ugc/avatar_placeholder.png' alt="" />,
        name: 'Robert Kuskildin',
        designation: 'Investor | PE & VC | M&A | Fundraising',
        logo: <img src={LinkedInIcon} alt="" />,
        classNames: 'pb-5'
      },


    ]
  }
}



export default stepConferenceData;