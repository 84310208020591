import React from "react"
import Header from "../../../components/Header"
import HeroSecondary from "../../../components/inner-page/HeroSecondary"
import Layout from "../../../components/Layout"
import FeatureCards from "../../../components/inner-page/FeatureCards"
import MainFeaturesSectionSecondary from "../../../components/inner-page/MainFeaturesSectionSecondary"
import EventTypes from "../../../components/inner-page/EventTypes"
import CustomerFeedback from "../../../components/inner-page/CustomerFeedback"
import TrustedBy from "../../../components/inner-page/TrustedBy"
import MagicWithUs from "../../../components/inner-page/MagicWithUs"

export function Index() {
  return (
    <Layout title="Premagic">
      <Header />
      <HeroSecondary />
      <FeatureCards />
      <MainFeaturesSectionSecondary />
      <TrustedBy />
      <CustomerFeedback />
      <EventTypes />
      <MagicWithUs />
    </Layout>
  )
}

export default Index
