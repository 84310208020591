import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Flickity from "react-flickity-component"
import useWindowDimensions from "../../utils/useWindowDimensions"
import { Link } from "gatsby"
import {
  btn,
  btnSmall,
  btnDefault,
  btnPrimary,
} from "../../ds/button.module.css"
import { WhiteArrowRight } from "../../ds/Shapes"

const featuresData = [
  {
    title: "Real-Time Photo Distribution",
    desciption:
      "Instantly share event memories with attendees through our rapid photo distribution.",
    bgColor: "#FFF1EF",
    titleColor: "#E15D4B",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_1.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Sponsorship Visibility",
    desciption:
      "Give your sponsor the spotlight they deserve with a front-row seat to engage with a broader audience.",
    bgColor: "#EAFFF8",
    titleColor: "#109A6D",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_2.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Organic UGC",
    desciption:
      "Let your attendees become your brand advocates, sharing their epic experiences long after the curtains close.",
    bgColor: "#FFF7E8",
    titleColor: "#613F00",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_3.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "From Photo Dump to AI-Galleries",
    desciption: "Turn photo chaos into sleek customized AI albums in minutes.",
    bgColor: "#F5F2FF",
    titleColor: "#1F0F61",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_4.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Measurable Data & Analytics",
    desciption: "Dive into event data and insights to measure event success.",
    bgColor: "#F3FFD0",
    titleColor: "#324100",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_5.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Digital Asset Management (DAM)",
    desciption:
      "Manage and organize digital assets for your events with absolutely no hassle.",
    bgColor: "#F2FFFE",
    titleColor: "#005C55",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_6.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Specialized Event Webpage",
    desciption: "Create a dedicated webpage tailored to your event.",
    bgColor: "#FFF1EF",
    titleColor: "#E15D4B",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_7.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Networking to the Next Level",
    desciption:
      "Elevate attendee interactions and connections with powerful networking tools.",
    bgColor: "#EAFFF8",
    titleColor: "#109A6D",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_8.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "All-Year Event Marketing Content",
    desciption:
      "Keep your event in the spotlight with enough content for year-round marketing.",
    bgColor: "#FFF7E8",
    titleColor: "#613F00",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_9.png"
        alt="client magic motion media"
      />
    ),
  },
]

export function Features() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"

  const flickityOptions = {
    // autoPlay: 2000,
    // wrapAround: true,
    // selectedAttraction: 0.01,
    friction: 0.2,
    cellAlign: "left",
    contain: true,
    // contain: true,
    // pageDots: true,
    // prevNextButtons: true,
    // pauseAutoPlayOnHover: false
  }

  return (
    <section>
      <div
        className="mx-auto mt-10 mb-20 md:mb-20"
        style={{ width: containerWidth }}
      >
        <h2 className="hidden md:block text-black text-5xl font-bold text-center leading-tight md:leading-tight relative z-20">
          Double your event conversions <br />
          with a
          <div className="relative mx-2 inline z-10">
            <div className="relative inline-block z-5">
              <span
                style={{ backgroundColor: "#61F2C2" }}
                className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-1"
              ></span>
              <div className="relative z-2">single platform</div>
            </div>
          </div>
        </h2>
        <h2 className="md:hidden text-black text-3xl font-bold text-center leading-tight md:leading-tight relative z-20">
          Double your event conversions with a
          <div className="relative mx-2 inline z-10">
            <div className="relative inline-block z-5">
              <span
                style={{ backgroundColor: "#61F2C2" }}
                className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-0"
              ></span>
              <div className="relative z-2">single platform</div>
            </div>
          </div>
        </h2>
        <p className="mt-10 mb-14 text-base md:text-lg opacity-60 text-center px-5 md:px-0">
          Get started today and watch your event ROI soar by 400%
        </p>
        <div className="relative no-scrollbar" style={{ height: "400px" }}>
          <div className="">
            <div className="">
              <Flickity
                className="carousel outline-none" // default ''
                // elementType="div"
                options={flickityOptions} // takes flickity options {}
                // disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                // static // default false
              >
                {featuresData.map(item => (
                  <div
                    className="px-2"
                    style={{ width: "328px", height: "400px" }}
                  >
                    <div
                      className="rounded-xl h-full flex flex-col"
                      style={{ backgroundColor: item.bgColor }}
                    >
                      <div className="p-8 pb-0">
                        <h2
                          className="font-bold text-xl md:text-2xl text-black"
                          style={{ color: item.titleColor }}
                        >
                          {item.title}
                        </h2>
                        <p className="text-sm mt-5">{item.desciption}</p>
                      </div>
                      <div className="rounded-xl overflow-hidden text-right mt-auto">
                        {item.image}
                      </div>
                    </div>
                  </div>
                ))}
              </Flickity>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Link
            to="/request-a-demo/"
            className={`${btn} ${btnPrimary} text-xs relative w-max group mt-10`}
          >
            <span className="mr-4 ml-2">Get a Demo</span>
            <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Features
