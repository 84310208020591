import { StaticImage } from "gatsby-plugin-image"
import useWindowDimensions from "../../utils/useWindowDimensions"
import {
  SmilingFace,
  MultiStars,
  Rocket,
  SpeedoMeter,
  Box,
  OrangeTick,
} from "../../ds/Shapes"
import { useState } from "react"
import { Link } from "gatsby"
import {
  btn,
  btnSmall,
  btnDefault,
  btnPrimary,
} from "../../ds/button.module.css"
import { WhiteArrowRight } from "../../ds/Shapes"

export enum TAB_TYPES {
  ATTENDEE_HAPPINESS = "attendee_happiness",
  BRAND_SHOWCASE = "brand_showcase",
  ROI_GAINS = "roi_gains",
  ENGAGEMENT_BOOSTER = "engagement_booster",
  FUTURE_STRATEGIES = "future_strategies",
}

const KEY_FEATURES = {
  [TAB_TYPES.ATTENDEE_HAPPINESS]: {
    tabTitle: "Attendee Happiness",
    title: "Impress the heck out of your audience",
    descriptionPoints: [
      "Give your audience instant access to event memories with real-time photo distribution.",
      "Find faces in minutes with face search powered by face recognition.",
      "Fully customizable and brandable AI-powered galleries",
    ],
    image: <StaticImage src="../../images/keyfeatures/1.png" alt="" />,
    icon: color => <SmilingFace color={color} />,
  },
  [TAB_TYPES.BRAND_SHOWCASE]: {
    tabTitle: "Brand Showcase",
    title: "Showcase your brand at every stage",
    descriptionPoints: [
      "Create a true extension of your brand with custom landing pages.",
      "Personalise each photo with brand watermarks.",
      "Position your brand with clickable in-gallery brand placement.",
    ],
    image: <StaticImage src="../../images/keyfeatures/2.png" alt="" />,
    icon: color => <MultiStars color={color} />,
  },
  [TAB_TYPES.ROI_GAINS]: {
    tabTitle: "ROI Gains",
    title: "Deliver higher click-through rates (CTR) for sponsors",
    descriptionPoints: [
      "Supercharge sponsor visibility with in-gallery ad placement",
      "Enable attendees to engage with sponsor brands with a direct CTA button",
      "Demonstrate clear ROI for your sponsor with detailed data and metrics",
    ],
    image: <StaticImage src="../../images/keyfeatures/3.png" alt="" />,
    icon: color => <Rocket color={color} />,
  },
  [TAB_TYPES.ENGAGEMENT_BOOSTER]: {
    tabTitle: "Engagement Booster",
    title: "Level up your attendee engagement",
    descriptionPoints: [
      "Easily create and share snackable event content with a single click.",
      "Make powerful connections with robust networking tools.",
      "Harness the power of user-generated content (UGC) to enhance your event marketing.",
    ],
    image: <StaticImage src="../../images/keyfeatures/4.png" alt="" />,
    icon: color => <SpeedoMeter color={color} />,
  },
  [TAB_TYPES.FUTURE_STRATEGIES]: {
    tabTitle: "Future Strategies",
    title: "Transform your event data into gold",
    descriptionPoints: [
      "Track conversions & create new sales touchpoints with detailed analytics",
      "Store, organise and manage event content with a powerful DAM system",
      "Lower your Cost Per Acquisition (CPA) by repurposing event photos for future marketing.",
    ],
    image: <StaticImage src="../../images/keyfeatures/5.png" alt="" />,
    icon: color => <Box color={color} />,
  },
}

export function KeyFeatures() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"
  const [activeTab, setActiveTab] = useState("attendee_happiness")

  return (
    <section className="mb-20" style={{ backgroundColor: "#F5F2FF" }}>
      <div className="mx-auto py-20" style={{ width: containerWidth }}>
        <h2 className="hidden md:block text-black text-5xl font-bold text-center leading-tight md:leading-tight relative z-20">
          From Pre-Event to Post-Event
          <br />
          we’ve got you covered
        </h2>
        <h2 className="md:hidden text-black text-3xl font-bold text-center leading-tight md:leading-tight relative z-20">
          From Pre-Event to <br />
          Post-Event we’ve got you covered
        </h2>
        <p className="mt-10 mb-14 text-base md:text-lg opacity-60 text-center px-5 md:px-0">
          Comprehensive solutions to elevate your event marketing strategies
        </p>
        <div className="flex justify-between bg-white rounded-xl p-2 overflow-scroll no-scrollbar mx-5 md:mx-0">
          {Object.keys(KEY_FEATURES).map(key => (
            <div
              className="mr-2 md:mx-0 flex items-center p-4 rounded-xl bg-white cursor-pointer hover:bg-purple-50 transition duration-300 ease-in-out"
              style={{ backgroundColor: activeTab === key ? "#7251F4" : "" }}
              onClick={() => setActiveTab(key)}
            >
              <div className="scale-125 md:scale-100">
                {KEY_FEATURES[key].icon(
                  activeTab === key ? "#FFFFFF" : "#121212"
                )}
              </div>
              <p
                className={`ml-3 font-medium text-sm ${
                  activeTab === key ? "text-white" : "text-black"
                } `}
              >
                {KEY_FEATURES[key].tabTitle}
              </p>
            </div>
          ))}
        </div>
        <div className="flex items-center mt-14 flex-wrap px-10 md:px-0">
          <div className="w-full md:w-1/2 md:pr-16">
            <h2 className="text-black text-2xl md:text-4xl font-bold mb-10 leading-tight md:leading-tight relative z-20">
              {KEY_FEATURES[activeTab].title}
            </h2>
            {KEY_FEATURES[activeTab].descriptionPoints.map(item => (
              <div className="flex">
                <OrangeTick />
                <p className="text-sm ml-2 mb-5">{item}</p>
              </div>
            ))}
          </div>
          <div className="w-full md:w-1/2 mt-5 md:mt-0">
            {KEY_FEATURES[activeTab].image}
          </div>
        </div>
        <div className="text-center">
          <Link
            to="/request-a-demo/"
            className={`${btn} ${btnPrimary} text-xs relative w-max group mt-10`}
          >
            <span className="mr-4 ml-2">Get a Demo</span>
            <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default KeyFeatures
