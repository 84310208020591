import { StaticImage } from "gatsby-plugin-image"
import useWindowDimensions from "../../utils/useWindowDimensions"
import {
  SmilingFace,
  MultiStars,
  Rocket,
  SpeedoMeter,
  Box,
  OrangeTick,
} from "../../ds/Shapes"
import { useState } from "react"
import { Link } from "gatsby"
import {
  btn,
  btnSmall,
  btnDefault,
  btnPrimary,
} from "../../ds/button.module.css"
import { WhiteArrowRight } from "../../ds/Shapes"

export function Index() {
  return (
    <section className="mb-20">
      <div className="mx-auto py-20 md:w-[1080px]">
        <div className="md:flex items-center">
          <div className="md:w-1/2">
            <StaticImage src="../../images/customize_1.png" alt="" />
          </div>
          <div className="md:w-1/2 pl-10 md:pl-20">
            <h2
              className="font-semibold leading-none"
              style={{ color: "#7251F4" }}
            >
              Customizable event landing pages
            </h2>
            <p className="my-8">
              Create stunning customizable event landing pages that provide the
              ultimate registration experience. Showcase your speakers and
              sponsors and your event agenda to captivate potential attendees.
            </p>
            <Link
              to="/request-a-demo/"
              className={`${btn} ${btnPrimary} text-xs mt-5 mb-10 md:mb-0 relative w-max group`}
            >
              <span className="mr-4 ml-2">Show me how</span>
              <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
            </Link>
          </div>
        </div>
        <div className="md:flex mt-32 px-10 md:px-0">
          <div className="md:w-1/2 md:pr-10">
            <StaticImage
              height={165}
              src="../../images/customize_2.png"
              alt=""
            />
            <h3
              className="font-semibold text-2xl leading-none mb-10"
              style={{ color: "#7251F4" }}
            >
              Engaging Registration Experience
            </h3>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Sleek and user-friendly branded forms that embed anywhere
              </p>
            </div>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Customizable fields to collect essential attendee information.
              </p>
            </div>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Instant confirmations and personalized emails to keep your
                attendees informed and excited.
              </p>
            </div>
          </div>
          <div className="md:w-1/2 md:pl-10">
            <StaticImage
              height={165}
              src="../../images/customize_3.png"
              alt=""
            />
            <h3
              className="font-semibold text-2xl leading-none mb-10"
              style={{ color: "#7251F4" }}
            >
              Seamless Multi-Zone Check-ins
            </h3>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Mark attendee attendance with secure and swift face check ins.
              </p>
            </div>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Maximize security by eliminating transferable credentials and
                ensuring accurate attendee tracking.
              </p>
            </div>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Manage seamless multi-zone check-ins for designated event spaces
                within a venue.
              </p>
            </div>
          </div>
        </div>
        <div className="md:flex mt-20 px-10 md:px-0">
          <div className="md:w-1/2 md:pr-10">
            <StaticImage
              className="mt-8"
              height={120}
              src="../../images/customize_4.png"
              alt=""
            />
            <h3
              className="font-semibold text-2xl leading-none mb-10 mt-8"
              style={{ color: "#7251F4" }}
            >
              Showcase Your Speakers & Event Agenda
            </h3>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Feature dedicated profiles with bios, photos, and social media
                links.
              </p>
            </div>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Display a detailed agenda with session times, topics, and
                speaker information.
              </p>
            </div>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Create dynamic, multi-track agendas that attendees can add to
                their personal calendars.
              </p>
            </div>
          </div>
          <div className="md:w-1/2 md:pl-10">
            <StaticImage
              height={165}
              src="../../images/customize_5.png"
              alt=""
            />
            <h3
              className="font-semibold text-2xl leading-none mb-10 mt-8"
              style={{ color: "#7251F4" }}
            >
              Promote Your Sponsors
            </h3>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Dedicated sponsor sections with logos, company descriptions, and
                website links.
              </p>
            </div>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Premium placement options for top-tier sponsors.
              </p>
            </div>
            <div className="relative mb-5">
              <div
                className="absolute left-0 top-2 w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: "#FF6550" }}
              />
              <p className="ml-5">
                Include interactive elements such as sponsor videos or
                downloadable content to engage visitors.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Index
