import React from "react"
import Lottie from "lottie-react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Footer from "../../components/Footer"
import tickAnimation from "../../lottie-files/tick.json"
import { WhiteArrowRight } from "../../ds/Shapes"

export default function SignatureSuccess() {
  return (
    <>
      <Layout title="We have received your request" hasFooter={false}>
        <div className="pm-container mt-10">
          <Link to="/">
            <StaticImage
              src="../../images/logo/premagic-logo@2xl.svg"
              width={150}
              alt="Premagic"
              placeholder="blurred"
            />
          </Link>
        </div>
        <div
          className="pm-container flex justify-center items-center"
          // style={{ height: "calc(100vh - 207px)" }}
        >
          <div className="mt-20">
            <div className="flex justify-center">
              <Lottie
                animationData={tickAnimation}
                loop={false}
                style={{ width: "100px" }}
              />
            </div>
            <h1 className="text-center font-bold text-black mt-5">Success!</h1>
            <p className="mt-8 text-center text-base opacity-70 text-black mb-20 md:hidden">
              You've just taken the first step towards creating event magic. Our
              experts will be in touch with you shortly.
            </p>
            <p className="mt-8 text-center text-base opacity-70 text-black mb-20 hidden md:block">
              You've just taken the first step towards creating event magic.
              <br />
              Our experts will be in touch with you shortly.
            </p>
            <div
              className="rounded-xl p-5 flex w-full md:w-1/2 mx-auto mb-20"
              style={{ backgroundColor: "#7251F4" }}
            >
              <StaticImage
                className="rounded-xl"
                src="../../images/globe_icon.png"
                alt=""
              />
              <div className="ml-5 flex flex-col justify-center">
                <p className="text-white text-sm">
                  Meanwhile, feel free to browse through some of our success
                  stories to discover what makes Premagic truly special.
                </p>
                <Link
                  to="/case-study/"
                  className="text-white flex items-center mr-10 mt-4 text-sm font-semibold"
                >
                  Take me there
                  <WhiteArrowRight className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Footer />
    </>
  )
}
