import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  btn,
  btnDefault,
  playButton,
  playButtonIcon,
} from "../../ds/button.module.css"
import { ArrowRightWithGradient } from "../../ds/Shapes"
import { cardBorderBottomBlue } from "../../ds/card.module.css"
import useWindowDimensions from "../../utils/useWindowDimensions"
import {
  CheckIconPink,
  CheckIconViolet,
  CheckIconBrown,
  CheckIconPurple,
} from "../../ds/SvgIcons"

const mainFeatures = [
  {
    image: <StaticImage src="../../images/main-features/001.png" alt="" />,
    title: "Turn your attendees into content creators",
    icon: CheckIconPink,
    descriptions: [
      "Your audience leaves the event with content that will keep the conversations going long after the event wraps up— and not just another branded t-shirt.",
      "Let your audience take the wheel in creating event content. Tap into the power of UGC (user-generated content) to make the most out of your investment.",
    ],
  },
  {
    image: <StaticImage src="../../images/main-features/002.png" alt="" />,
    title: "Show your sponsor’s brands some love",
    icon: CheckIconViolet,
    descriptions: [
      "Give your sponsor brands the spotlight they deserve. Attract new sponsors and retain current ones by giving them a front-row seat to engage with a broader audience.",
      "With a prime platform to spotlight sponsor brands and a custom call-to-action, your sponsors are sure to come back for more.",
    ],
  },
  {
    image: <StaticImage src="../../images/main-features/003.png" alt="" />,
    title: "Level up your attendee engagement",
    icon: CheckIconBrown,
    descriptions: [
      "Don’t just host your attendees - engage them. And leave the outdated engagement strategies at the door.",
      `With real-time photo distribution, you can drive audience engagement and bump up your event's visibility while creating unique event experiences like never before.`,
    ],
  },
  {
    image: <StaticImage src="../../images/main-features/004.png" alt="" />,
    title: "Transform your event data into gold",
    icon: CheckIconPurple,
    descriptions: [
      "Numbers don’t lie – Find out what clicked and what didn’t with our analytic tools.",
      "Do more with deep insights to elevate your strategy. Make data-driven decisions to deliver events that generate ROI that keeps on giving.",
    ],
  },
]

export function MainFeaturesSection() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 768
  if (isMobileUp) {
    return (
      <div className="pm-container mx-auto px-6 my-24">
        <h1 className="text-center font-bold text-5xl mt-10 leading-tight text-black leading-snug">
          Ditch the old-school marketing playbook{" "}
        </h1>
        <p className="text-black text-center mt-10 mb-10">
          Ignite event magic with the perfect corporate event marketing tool
        </p>
        {mainFeatures.map((item, idx) => (
          <div
            className={`flex my-20 ${idx % 2 === 0 ? "" : "flex-row-reverse"}`}
          >
            <div className="w-1/2">{item.image}</div>
            <div
              className={`w-1/2 flex flex-col justify-center ${
                idx % 2 === 0 ? "pl-20" : "pr-20"
              }`}
            >
              <h2 className="text-black text-3xl font-semibold leading-snug">
                {item.title}
              </h2>
              {item.descriptions.map(description => (
                <div className="flex mt-10">
                  <div className="mr-5">
                    <item.icon />
                  </div>
                  <div>
                    <p className="text-base text-black">{description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="pm-container mx-auto px-6 my-24">
      <h1 className="text-center font-bold text-3xl mt-10 leading-tight text-black">
        Ditch the old-school <br /> marketing playbook
      </h1>
      <p className="text-black text-base text-center mt-10 mb-10">
        Ignite event magic with the perfect <br /> corporate event marketing
        tool
      </p>
      {mainFeatures.map((item, idx) => (
        <div className="my-20">
          <div className="mb-10 overflow-hidden rounded-2xl">{item.image}</div>
          <div className="pt-2">
            <h2 className="text-black text-xl font-semibold leading-snug pr-5">
              {item.title}
            </h2>
            {item.descriptions.map(description => (
              <div className="flex mt-10">
                <div className="mr-5">
                  <item.icon />
                </div>
                <div>
                  <p className="text-base text-black pr-5">{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default MainFeaturesSection
