
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import Header from '../../../components/Header';
import Layout from '../../../components/Layout';
import ThumbnailImage from '../../../images/thumbnails/step_thumb.png';

export function Index() {
  return (
    <Layout 
    title="Discover how Step 2024 turned attendees into advocates with Premagic"
    description="Find out how Step team used Premagic’s versatile solutions to enhance attendee engagement and organic marketing at Step Conference 2024"
    metaImage={ThumbnailImage}
    >
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.STEPCONFERENCE} />
    </Layout>
  );
}

export default Index;