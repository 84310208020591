import { StaticImage } from "gatsby-plugin-image"

import icon1 from "../../images/svg/1.svg"
import icon2 from "../../images/svg/2.svg"
import icon3 from "../../images/svg/3.svg"
import icon4 from "../../images/svg/4.svg"

const mercedesBenzData = {
  color: "#E5FFF9",
  heroTitle: `Explore how Coastal Star Embraced Premagic to Enhance the Mercedes-Benz Experience`,
  heroImage: (
    <StaticImage src="../../images/case-study/mercedesbenz/1.png" alt="" />
  ),
  metrics: {
    values: [
      {
        label: "Attendees",
        value: "120",
      },
      {
        label: "Photos",
        value: "1,570",
      },
      {
        label: "Photo Downloads",
        value: "290",
      },
      {
        label: "Social Posts",
        value: "60",
      },
    ],
    title: "Background",
    content: `The EQE SUV showcase was an elite event, offering an exclusive first look at the cutting-edge, all-electric EQE SUV. This gathering was meticulously organized by Mercedes-Benz Coastal Star, the franchise partner representing Mercedes-Benz India Pvt. Ltd. It was an exclusive affair, with over 120 distinguished Mercedes owners in attendance, at Kochi to witness the future of automotive innovation.`,
    footer: `The event served as a testament to Mercedes-Benz's unwavering commitment to pioneering advancements in the automotive industry, setting the stage for a new era of sustainable, electric mobility.`,
    host: "Mercedes-Benz Coastal Star",
    organizer: "Mercedes-Benz Coastal Star",
    industry: "Luxury Automobile",
  },
  images: [
    {
      img: (
        <StaticImage src="../../images/case-study/mercedesbenz/2.png" alt="" />
      ),
    },
    {
      img: (
        <StaticImage src="../../images/case-study/mercedesbenz/3.png" alt="" />
      ),
    },
  ],
  gallery: (
    <StaticImage
      src="../../images/case-study/mercedesbenz/gallery.png"
      alt=""
    />
  ),
  caseStudyData: [
    {
      title: "The Challenge",
      subheading: "Elevating event standards to align with brand values",
      info1: `Mercedes-Benz is synonymous with elegance and luxury, representing the pinnacle of automotive excellence. The brand's vehicles embody opulence, and when it comes to hosting an event under the esteemed Mercedes-Benz franchise, every detail must exude sophistication and refinement. <br /><br />While Coastal Star had organized numerous similar showcase events in the past, they had yet to tap into the world of event tech, specifically a robust photo distribution platform. Consequently, the photos from these prestigious events were often distributed haphazardly, through bulk WhatsApp messages or heavy Google Drive folders. This lack of a structured approach resulted in confusion over who received which photos, often leaving attendees in the dark about the whereabouts of their event snapshots.`,
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/mercedesbenz/avatar.png"
            alt=""
          />
        ),
        message: `“ We had recognised the need for a premium photo distribution solution like Premagic when it comes to delivering images of car deliveries. “`,
        name: "Thomas Alex",
        info: `Managing Director,<br />Mercedes-Benz Coastal Star`,
      },
      info2: `These images, which had great potential to turn into user-generated content on social media, were being sent to clients in a rather unprofessional manner, merely as a collection of WhatsApp photos or Google Drive folder. And this approach did not align with the brand values upheld by Mercedes-Benz. <br /><br />Coastal Star decided to explore Premagic during the EQE SUV showcase before implementing this innovative solution for distributing car purchase and delivery images to their discerning clientele.`,
    },
    {
      title: "The Solution",
      subheading: "A refined solution for premium event photo delivery",
      info1: `A premium event needs a premium solution! Delivering the event photos to 120+ attendees promptly and seamlessly was the primary target that Premagic addressed. This task was significant, considering the team's commitment to maintaining a premium and high-quality event experience.<br /><br />
                “Premagic’s AI-based photo distribution aligned seamlessly with Mercedes-Benz's commitment to excellence.”<br /><br />
                The attendees had the option to register their selfies for real-time photo delivery. Alternatively, they could also easily find their photos within the customized Premagic gallery, thanks to facial recognition technology.<br /><br />
                To further enhance the attendee experience, the Coastal Star team sent personalized WhatsApp messages to all attendees, providing easy access to the event photo album and individual pictures.`,
    },
    {
      title: "The Results",
      subheading: "Delivering a premium community-oriented experience",
      info1: `Premagic's AI-based photo distribution delivered more than 1500 photos to the attendees, resulting in nearly 1000 views. The guests were delighted with Premagic's elegant and user-friendly gallery interface, as well as the seamless accessibility it provided for the photographs. <br /><br />
                The Coastal Star team was thoroughly impressed when they observed their customers receiving event photos in real time. As the event progressed, their team strategically distributed QR codes, effectively leveraging them to enhance the excitement of the event. The outcome was an overwhelmingly positive response, with almost all attendees sharing a resounding "wow" experience.`,
      gridViewData: [
        {
          icon: <img src={icon1} alt="" />,
          title: "Real-time photo delivery",
          description: `Achieved real-time photo delivery for over 1500 photos, resulting in nearly 1000 views.`,
        },
        {
          icon: <img src={icon2} alt="" />,
          title: "A premium way to deliver photos",
          description: `Presented an elegant and sleek photo gallery that impressed event attendees.`,
        },
        {
          icon: <img src={icon3} alt="" />,
          title: "Face search option",
          description: `Provided easy access to event photographs with the option of face search.`,
        },
        {
          icon: <img src={icon4} alt="" />,
          title: "Secure and professional",
          description: `Delivered a secure and professional photo distribution service to match the event's premium standards.`,
        },
      ],
    },
    {
      title: "The Future",
      subheading: `Delivering a premium community-oriented experience`,
      info1: `The EQE-SUV showcase served as a "test drive" for Premagic before the Coastal Star team decided to adopt it as their official photo distribution platform. After studying the feedback at the event, the team was more than satisfied with the solutions offered by Premagic. <br /><br />
                Thomas Alex expressed his satisfaction, stating,`,
      testimonial: {
        img: (
          <StaticImage
            src="../../images/case-study/mercedesbenz/avatar.png"
            alt=""
          />
        ),
        message: `“ Premagic has surpassed our expectations in every way. Quick delivery in a wonderful package. That's exactly what we had been looking for. “`,
        name: "Thomas Alex",
        info: `Managing Director,<br />Mercedes-Benz Coastal Star`,
      },
    },
  ],
}

export default mercedesBenzData
