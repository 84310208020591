import React, { useEffect, useState } from "react"

import { StaticImage } from "gatsby-plugin-image"

import LeftcpsuleIcon from "../../../images/svgs/leftcapsule.svg"
import RightcpsuleIcon from "../../../images/svgs/rightcapsule.svg"

export function Hero() {
  const changingTexts = ["understand", "support ", "empower"]

  const [currentIndex, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(currentIndex => (currentIndex + 1) % 3)
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  return (
    <section className="relative">
      <div className="absolute left-0 top-20 md:top-1/2 md:-translate-y-1/3">
        <img src={LeftcpsuleIcon} alt="" />
      </div>
      <div className="absolute right-0 -top-10 mt-10 md:mt-0 md:top-1/2 md:-translate-y-1/2">
        <img src={RightcpsuleIcon} alt="" />
      </div>
      <div className="container mx-auto px-6">
        <div className="relative rounded-lg pb-4">
          <div className="relative text-center md:p-28 md:pb-0 pt-8 md:pt-14 pb-8">
            <h1 className="hidden md:block font-bold text-6xl text-black leading-tight">
              Our customer’s success is our
              <br />
              success. We
              <span
                className=" before:block before:absolute before:-inset-1 relative inline-block mx-2 px-3"
                style={{ backgroundColor: "#7251F4" }}
              >
                <span className="relative text-white">
                  {changingTexts[currentIndex]}
                </span>
              </span>
              them.
            </h1>
            <h1 className="md:hidden font-bold text-3xl text-left text-black leading-tight">
              Our customer’s success is our
              <br />
              success. We
              <br />
              <span
                className="before:block before:absolute before:-inset-1 relative inline-block mr-2 px-3"
                style={{ backgroundColor: "#7251F4" }}
              >
                <span className="relative text-white">
                  {changingTexts[currentIndex]}
                </span>
              </span>
              them.
            </h1>
            <p className="my-5 md:my-7 mb-0 font-medium text-black text-left md:text-center">
              The future of events is a story we’re writing with our customers
            </p>
            <StaticImage
              className="mt-10 -mx-5"
              src="../../../images/case-study/hero.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
