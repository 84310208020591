import { StaticImage } from "gatsby-plugin-image"

import icon1 from "../../images/svg/1.svg"
import icon2 from "../../images/svg/2.svg"
import icon3 from "../../images/svg/3.svg"
import icon4 from "../../images/svg/4.svg"

const tdpData = {
  color: "#FDF36D",
  heroTitle: `Learn how Nara Lokesh's Yuvagalam Padayatra used Premagic to drive TDP's success.`,
  heroImage: <StaticImage src="../../images/case-study/tdp/1.png" alt="" />,
  metrics: {
    values: [
      {
        label: "Attendees",
        value: "180232",
      },
      {
        label: "Photos",
        value: "301684",
      },
      {
        label: "Photo Downloads",
        value: "209080",
      },
      {
        label: "Social Posts",
        value: "190452",
      },
    ],
    title: "Background",
    content: `Learn how Chandrababu Naidu's TDP harnessed Premagic’s AI-powered solutions for a winning campaign at the Yuva Galam Padayatra.`,
    footer: `The Telugu Desam Party (TDP) cruised to a remarkable victory in Andhra Pradesh. The party and its allies won 164 out of the 175 constituencies in the Andhra Pradesh Assembly and 21 out of the 25 Parliament seats. Chandrababu Naidu emerged as the kingmaker with the next government needing his support. One key factor that contributed to TDP's success was their effective use of Premagic to amplify their election campaign.`,
    host: "Telugu Desam Party",
    organizer: "Telugu Desam Party",
    industry: "Political campaign",
  },
  images: [
    {
      img: <StaticImage src="../../images/case-study/tdp/2.png" alt="" />,
    },
    {
      img: <StaticImage src="../../images/case-study/tdp/3.png" alt="" />,
    },
  ],
  gallery: <StaticImage src="../../images/case-study/tdp/gallery.png" alt="" />,
  caseStudyData: [
    {
      title: "The Challenge",
      subheading:
        "Very low engagement with photos and virtually no digital footprint.",
      info1: `In India's competitive political landscape, technology is vital for candidates and parties to meet voter expectations.`,
      info2: `To engage the youth audience at the Yuva Galam Padayatra, the TDP team desired enhanced online visibility. They initiated a "Photo with the Leader" opportunity at every location, where local youth could participate by taking a photo with Nara Lokesh <br /> <br />
                They had set up an S3 server, where all the photographs were manually uploaded to a server, allowing individuals to download them later. However, the upload process was time-consuming, and the bigger challenge lay in sifting through thousands of images to locate the specific ones needed by the constituents. <br /> <br />
                Engagement was minimal as the constituents of Yuva Galam faced difficulties accessing the photos. The cumbersome process resulted in only a handful of these photos organically reaching social media platforms.<br /> <br />
                This inefficiency hindered their goal of enabling constituents to instantly post content about the rally on social media that could boost the party's visibility and support.`,
    },
    {
      title: "The Solution",
      subheading: "Swift photo delivery = More social engagement",
      info1: `From our experience, we knew that the faster event photos are distributed, the higher the chances of people posting them on social media. Our AI-powered photo distribution paired with facial recognition, enabled the delivery of personalized event galleries to each participant of Yuva Galam. They could also easily find their photos using the face search feature, where they simply took a selfie, allowing the AI to gather relevant photos. <br /><br />
                In India, WhatsApp is ubiquitous among the youth, making photo distribution via WhatsApp critical. We were able to deliver photos to all attendees on the same day via WhatsApp, thus boosting the sharing of photos on social networking sites like Instagram and Facebook, as well as peer-to-peer sharing via WhatsApp, granting immense visibility to the Yuva Galam Padayatra and TDP among the youth.<br /><br />`,
      testimonial: {
        img: (
          <StaticImage src="../../images/case-study/tdp/avatar.jpeg" alt="" />
        ),
        message: `“ Sharing photos with Premagic made it easier for people to connect with our campaign message, and created an immediate buzz about Yuva Galam on social media “`,
        name: "Harish Ganti",
        info: "Member of Parliament, TDP",
      },
    },
    {
      title: "The Results",
      subheading: `Technology and Premagic Contribute to TDP's Resounding Victory`,
      info1: `The result is now evident for all to see. The Telugu Desam Party (TDP) clinched a spectacular win in Andhra Pradesh and secured a place on the NDA high table. A standout victory was that of Nara Lokesh, who was elected from the Mangalagiri Assembly constituency with a thumping majority for the first time. Lokesh, son of TDP supremo N. Chandrababu Naidu, polled an impressive 1,67,710 votes, securing a majority of 91,413 over his opponent M. Lavanya of the YSR Congress Party (YSRCP). This victory is especially noteworthy as he had lost in the same constituency by around 5,300 votes in 2019.<br /><br />
                We couldn't be more honoured to have played a part in this win. Here is how Premagic's AI-powered solutions aided the triumph of the Yuva Galam Padayatra and TDP’s campaigns in several key ways:`,
      gridViewData: [
        {
          icon: <img src={icon1} alt="" />,
          title: "Instant Social Media Impact",
          description: `Through Premagic's user-friendly interface, constituents could instantly find and share professionally captured moments from the Yuva Galam Padayatra on social media. This immediacy created a persistent buzz around the campaign, especially Nara Lokesh's Yuva Galam Padayatra, expanding its reach far beyond those physically present. Each shared photo became a digital advocate for the walkathon, creating a viral wave that amplified Nara Lokesh's message far beyond the physical reach of the Padayatra.`,
        },
        {
          icon: <img src={icon2} alt="" />,
          title: "Enhanced Voter Engagement",
          description: `The ease of accessing and sharing photos strengthened the connection between TDP and its constituents, particularly the youth. This digital engagement translated into real-world support, as evident in the dramatic turnaround in Mangalagiri where Lokesh's margin went from a deficit of 5,300 to a surplus of over 91,000 votes.`,
        },
        {
          icon: <img src={icon3} alt="" />,
          title: "Data-Driven Campaign Refinement",
          description: `Premagic's analytics pinpointed the most impactful moments of the Yuva Galam Padayatra and identified districts with the highest engagement. The team also had dedicated dashboards to monitor registration rates on an hourly basis. This real-time data allowed the leadership team to quickly identify and address any issues in on-ground execution, ensuring the Padayatra ran smoothly. Additionally, the comprehensive attendee information gathered enabled the team to implement targeted drip campaigns post-event, maximizing engagement and outreach.`,
        },
        {
          icon: <img src={icon4} alt="" />,
          title: "Improved Efficiency",
          description: `By automating the time-consuming tasks of sorting, tagging, and distributing photos, Premagic freed up campaign staff to focus on ground-level engagement and strategizing. This efficiency likely contributed to the comprehensive nature of TDP's victory across Andhra Pradesh.`,
        },
      ],
    },
    {
      title: "The Future",
      subheading: `Sustaining tech-driven campaigns`,
      info1: `While many factors contribute to an election victory, the role of Premagic in TDP's Yuva Galam Padayatra campaign cannot be understated. By facilitating instant, widespread sharing of campaign moments, providing actionable insights, and streamlining operations, Premagic helped TDP effectively leverage technology to connect with voters. The results - a landslide win, a place in the NDA, and Nara Lokesh's personal triumph in Mangalagiri - underscore the potency of a tech-savvy, people-centric campaign strategy. As political landscapes increasingly intersect with digital realms, tools like Premagic are set to become indispensable in shaping electoral outcomes.<br /><br />
                The TDP plans to continue its partnership with Premagic, incorporating AI-based photo distribution consistently in future events to effectively engage constituents and convey messages. Having realized the transformative power of technology in their recent electoral triumph, the party sees Premagic as an indispensable ally for upcoming campaigns. They envision leveraging Premagic's evolving AI capabilities not just for photo management, but also to gain deeper insights into public sentiments, personalize outreach strategies, and stay ahead in the digital political landscape.`,
    },
  ],
}

export default tdpData
